// font colours
$white: #fff;
$whiteOnDarkGreen: #f9f9f7;
$whiteWithGreenTone: #f8f7f0;
$seeThruWhiteWithGreenTone: rgba( 248, 247, 240, 0.6);
$lightGreen: #ADAD94;
$subtleOnDarkGreen: #A3A38A;
$darkGreen: #545325;


// backgrounds
$inputBG: #E8E8C7;
$inputBGTop: #F4F4E3;
$whiteGreen: #fefef5;
$whiteGreen1: #eeeed5;
$whiteGreen2: #dcdcbd;
$whiteGreen3: #d1d1b3;
$pageBgGreen: #dcd7b4;
$pageBgGreen1: #bcbca1;
$grey: #c6c6c0;
$darkGrey: #949189;
$titleBg: #97966d;
$footerGreen: #545325;
$green: #27AE60;

// action
$action: #f2994a;
$actionDisabled: rgba(242,153,74,0.7);
$attention: #0E448C;
$orange: #f2994a;

// error
$failed: #ff9696;
$failedBG: rgba(255, 150, 150, 0.5);
$success: $whiteGreen3;
$successBG: $whiteGreen1;

