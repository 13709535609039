.widgets {

	.col1, .col2, .col3, .col {
		
		margin-bottom: 20px;

		> div,
		> a {
			min-height: 272px;
			padding-left: 24px;
			padding-right: 24px;
			position: relative;
			
			&._half {
				min-height: auto;
				padding-bottom: 32px;
			}

			.overlay{
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: rgba(0,0,0,0.5);
			}
		}
		a {
			&:hover {
				text-decoration: underline;	
				color: #FFF;
			}
		}

	    @include susy-media($desktop) {
	    	margin-bottom: 0;
	    }
	    
	}

	.packing-list,
	.share-packing-list {
		background: url('/img/assets/home-learn-safari.jpg') no-repeat;
		background-size: cover;
	}

	.stories {
		background: url('/img/thesafaristore-safari-stories.jpg') no-repeat;
		background-size: cover;
	}

	.returns {
		background: url('/img/assets/carousel-6.jpg') no-repeat;
		background-size: cover;
	}

	.packing-advice {
		background: url('/img/thesafaristore-safari-clothing-what-to-pack-for-your-safari-cotton-vs-manmade-fabric.jpg') no-repeat;
		background-size: cover;
	}

	.shop-outdoor {
		background: url('/img/thesafaristore-safari-clothing-what-to-pack-for-your-safari-cotton-vs-manmade-fabric.jpg') no-repeat;
		background-size: cover;
	}


	h3 {
		position: relative;
		margin: 0;
		padding: 32px 0 2px 0;
		font-weight: bold;
		color: $white;
		font-size: 22px;

		&.small {
			font-size: 20px;
		}

		&:after {
			content: "";
			position: absolute;
			height: 1px;
			bottom: 0;
			left: 0;
			width: 100%;
			background: #fff;
		}
	}

	p {
		position: relative;
		margin: 0;
		color: $white;
	}

	span {
		color: #FFF;
		font-size: 12px;
	}

	.header-link {

		display: block;

		h3 {

			&:after {
				position: absolute;
				content: "";
				width: 26px;
				height: 21px;
				left: 200px;
				bottom: 5px;
				background: url('/img/assets/arrow-right.svg') no-repeat;
				transition: left 0.3s;
			}

			&:hover:after {
				left: 214px;
			}
		}

		p {
			line-height: 1.4em;
			margin-top: 16px;
		}

	}

	/* MEDIA QURIES */

	@include susy-media($smallTablet) {

		&-story {
			@include span(6 of 12);

			h3 {
				a {
					&:after {
						left: 176px;
					}

					&:hover:after {
						left: 198px;
					}
				}
			}

			&._right {
				@include last;
			}

			&._right ._wrap {
				margin-left: 4px;
			}

			&._left ._wrap {
				margin-right: 4px;
			}
		}
	}

	@include susy-media($tabletLandscape) {
		&-story {
			h3 {
				a {
					&:after {
						left: 300px;
					}

					&:hover:after {
						left: 324px;
					}

				}
			}
		}
	}

	@include susy-media($laptop) {
		&-search {
			@include span(6 of 12);

			h3 {
				padding-left: 32px;
			}
		}

		&-search {
			._wrap {
				margin-right: 4px;
				> div {
					padding-left: 32px;
					padding-right: 0;
				}
			}
		}

		&-story {
			@include span(3 of 12);
			margin-top: 0;

			h3 {
				a {
					&:after {
						left: 176px;
					}

					&:hover:after {
						left: 198px;
					}
				}
			}

			&._right ._wrap {
				margin-left: 6px;
			}

			&._left ._wrap {
				margin-left: 4px;
				margin-right: 2px;
			}
		}
	}
}

.widgets-short {
	.col1, .col2, .col3, .col {
		
		> div,
		> a {
			min-height: 155px;
		}
	}
}