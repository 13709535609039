.news-page {


    h1 {
        font-size: 40px;
        font-weight: bold;
        margin: 20px 0 5px;
    }

    .news-page-date {
        color: $lightGreen;
        display: block;
        margin-bottom: 20px;
    }

    @include susy-breakpoint($smallTablet) {
        h1 {
            font-size: 60px;
            font-weight: bold;
            margin: 20px 0;
        }
    }

    h4 {
        text-align: center;
        color: $darkGreen;
    }

    @include susy-breakpoint($smallTablet) {
        .padding-right {
            padding-right: 20px;
        }
    }

    .news-page-content-area {
        

        h2 {
            font-size: 30px;
            text-align: center;
            margin: 15px 0;
        }

        @include susy-breakpoint($smallTablet) {
            h2 {
                font-size: 40px;
                text-align: center;
                margin: 35px 0;
            }
        }

        .col_3_3_3_3 {
            text-align: center;
        }

        img {
            margin: 10px 0;
        }

        p {
            font-size: 16px;
            line-height: 19px;
            @include susy-breakpoint($smallTablet) {
                font-size: 20px;
                line-height: 25px;
            }
            margin-bottom: 20px;
            text-align: justify;

            a {
                text-decoration: underline;
            }
        
            &:first-of-type {
                font-size: 20px;
                line-height: 25px;

                &::first-letter {
                    float:left;
                    font-size: 35px;
                }
            }

            @include susy-breakpoint($smallTablet) {
                &:first-of-type {
                    font-size: 26px;
                    line-height: 32px;

                    &::first-letter {
                        float:left;
                        font-size: 52px;
                    }
                }
            }
        }


        blockquote {
            font-size: 20px;
            text-align: center;
            margin: 15px 0;

            @include susy-breakpoint($smallTablet) {
                font-size: 35px;
                margin: 5px 0;
            }
        }
    }

    .icons {

        margin-bottom: 30px;
        margin-left: 45px;

        @include susy-breakpoint($smallTablet) {
            margin-left: 0;
            margin-bottom: 20px;
        }

        li {
            float:left;
            padding-right: 10px;
        }

        @include susy-breakpoint($smallTablet) {
            li {
                float:none;
                padding-right: 0;
            }
        }
        a {
            display: block;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
        }
    }

    .news-page-share {
        h4 {
            margin-top: 0;
        }
    }

    .news-avatar {

        overflow: hidden;

        img {
            border-radius: 75px;
        }

        &-name {
            font-size: 20px;
            display: block;
        }
        &-job-title {
            font-size: 16px;
            display: block;
        }
        &-side {
            display:none;
        }

        @include susy-breakpoint($smallTablet) {
            &-side {
                display:block;
            }
        }
    }

    .main-image {
        
        margin-bottom: 20px;
        

        @include susy-breakpoint($smallTablet) {
            margin-bottom: 40px;
        }
        .image-caption {
            color: $lightGreen;
            font-style: italic;
            display: block;
        }
    }

    .products {
        
        ._product {
            height: 420px;
            width: 80%;
            margin: 0 auto;

            h3 {
                font-size: 18px;
                margin-top: 5px;
                margin-bottom: 5px;
                color: black;
            }
        }
    }

}