.icn {
	position: relative;
	padding-left: 30px;

	&:before {
		content: "";
		position: absolute;
		width: 30px;
		height: 100%;
		top: 0;
		left: 0;
	}

	&-twitter:before {
		background: url('/img/assets/icn-twitter.svg') 0 50% no-repeat;
		background-size: contain;
	}

	&-twitter-green:before {
		background: url('/img/assets/icn-twitter-green.png') 0 50% no-repeat;
		background-size: contain;
	}

	&-fb:before {
		background: url('/img/assets/icn-fb.svg') 0 50% no-repeat;
		background-size: contain;
	}

	&-fb-green:before {
		background: url('/img/assets/icn-fb-green.png') 0 50% no-repeat;
		background-size: contain;
	}

	&-email-green:before {
		background: url('/img/assets/icn-email-green.png') 0 50% no-repeat;
		background-size: contain;
	}

	&-instagram:before {
		background: url('/img/assets/icn-instagram.svg') 0 50% no-repeat;
		background-size: contain;
	}

	&-pinterest:before {
		background: url('/img/assets/icn-pinterest.svg') 0 50% no-repeat;
		background-size: contain;
	}
}
