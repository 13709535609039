.box-links {

	margin: 30px auto;
	border-bottom: 1px solid $whiteGreen2;

	li {
		a {
			display: block;
			border-top: 1px solid $whiteGreen2;
			border-left: 1px solid $whiteGreen2;
			border-right: 1px solid $whiteGreen2;
			padding: 20px;
			box-shadow: 0 4px 4px -4px rgba(0,0,0,0.2);
    		transition: box-shadow 0.25s, border-color 0.25s;

			h3 {
				margin: 0 0 10px;
				color: #000;

    			transition: color 0.25s;
			}

			p {
				color: black;
				font-size: 18px;
				margin-bottom: 5px;
			}

			span {
				font-size: 12px;
				color: $lightGreen;
			}

			&:hover,
			&:focus {

				box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);

				h3 {
					color: $action;
				}
			}
		}
	}
}