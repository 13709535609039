section.deliver-world {
	position: relative;
	margin: 0;
	min-height: 112px;
	text-align: center;
	background: $whiteWithGreenTone;
	box-shadow: 0 2px 0 $subtleOnDarkGreen;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 2px;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dcd7b4+0,dcd7b4+50,514f23+51,514f23+100 */
		background: rgb(220,215,180); /* Old browsers */
		background: -moz-linear-gradient(left,  rgba(220,215,180,1) 0%, rgba(220,215,180,1) 50%, rgba(81,79,35,1) 51%, rgba(81,79,35,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  rgba(220,215,180,1) 0%,rgba(220,215,180,1) 50%,rgba(81,79,35,1) 51%,rgba(81,79,35,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  rgba(220,215,180,1) 0%,rgba(220,215,180,1) 50%,rgba(81,79,35,1) 51%,rgba(81,79,35,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		background-size: 6px;

	}

	h3, 
	a {
		position: relative;
		z-index: 1;
	}

	a {
		color: $footerGreen;
		font-style: italic;
		text-decoration: none;
		display: block;
		font-size: 20px;

		&.small {
			font-size: 15px;
		}

		&:last-of-type {
			/*margin-right: 32px;*/
			margin-top: 4px;
			margin-bottom: 16px;
		}
	}


	.title {
		font-weight: normal;

		a {
			text-decoration: none;
			font-style: normal;
			margin: 0;
			font-size: 36px;
		}

		&.small {
			a {
				font-size: 28px;
			}
		}
	}


	h3 {
		margin: 0;
		padding: 16px 0;
		color: $footerGreen;
	}



	strong {
		font-weight: bold;
	}

	img#deliver-plane {
		position: absolute;
		top: 14px;
		left: 0;
		transform: translate3d(0,0,0);
		transition: transform 0.2s;
	}

	&-wrap {
		position: relative;
	}

	@include susy-breakpoint($tablet) {
		a {
			display: inline;

			&:first-of-type {
				margin-right: 32px;
			}
		}
	}
}