button.submit,
a.submit {
	min-width: 80px;
	height: 40px;
	margin-top: 24px;
	padding: 0 8px;
	line-height: 36px;
	border: none;
	border-radius: 2px;
	background: $action;
	overflow: hidden;
	text-decoration: none;
	font-size: 14px;
	color: $white;

	&._left {
		float: left;
	}

	&._right {
		float: right;
	}
	&._wide {
		display: block;
		text-align: center;
	}
	&._no_margin {
		margin: 0;
	}
	&.large {
		padding: 10px 20px; 
	}
}

.form-mailing-list {
	width: 320px;
	margin-left: auto;
	margin-right: auto;
}

.form {

	.row {
		.left, .right {
			@include span(8 of 8);
		}
	}

	label {
		display: block;
		margin: 12px 0 8px;
		font-weight: bold;
		font-size: 18px;
		
		span{
			font-weight: normal;
			
			&.error_message{
				font-size: 14px;
				color: red;
			}
		}
	}

	input {
		width: 100%;
		height: 40px;
		padding: 0 8px;
		line-height: 40px;
		border: 1px solid $inputBG; 

		&._light {
			background: #f4f4e3;
		}

		&[type="checkbox"].inline,
		&[type="radio"].inline {
			float: left;
			margin-right: 10px;
			width: 25px;
			height: 25px;
			margin-top: -2px;
		}

		&._safari {
			background-color: $inputBG;
		}
		
		&._disabled {
			cursor: default;
			opacity: 0.6;
		}
	}

	select {
		width: 100%;
		padding: 8px 36px 8px 8px;
		height: 40px;
		border: 1px solid $inputBG; 
		border-radius: 0;
		overflow: hidden;
		background-image: url('/img/assets/icn-dropdown.svg');
		background-color: $white;
		background-position: calc(100% - 8px) center;
		background-repeat: no-repeat;
		background-size: 16px;
		font-size: inherit;
		text-overflow: ellipsis;
		white-space: nowrap;
		-webkit-appearance: button;
		/*-webkit-padding-end: 20px;
		-webkit-padding-start: 2px;
		-webkit-user-select: none;*/
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 0.01px;
		/*text-overflow: '';*/

		&._safari {
			background-color: $inputBG;
		}

		&._disabled {
			cursor: default;
			opacity: 0.6;
		}
	}

	._checkbox {
		label {}

		input {
			height: auto;
			display: inline-block;
			float: left;
			width: auto;
			margin-right: 8px;
			margin-top: 1px;
		}
	}

	button.submit,
	a.submit {
		/*min-width: 80px;
		height: 40px;
		margin-top: 24px;
		padding: 0 8px;
		line-height: 36px;
		border: none;
		border-radius: 2px;
		background: $action;
		overflow: hidden;
		text-decoration: none;
		font-size: 14px;
		color: $white;*/

		/*&._left {
			float: left;
		}

		&._right {
			float: right;
		}*/

		&._secondary {
			background: $darkGreen;
		}

		&._full-width {
			width: 100%;
		}

		&._disabled {
			cursor: default;
			opacity: 0.6;
		}
	}
	.delivery-postcode,
	.addressLookupArea {
		button.submit {
			min-width: 150px;
		}
	}
	#BasketDeliveryPostcodeButton {
		min-width: 200px;
	}
	a.submit{
		padding-top: 2px;
	}
	button._disabled{
		opacity: 0.6;
	}
	@include susy-media($smallTablet) {
		.row {
			@include span(12 of 12);
			.left {
				@include span(4 of 8);
			}
			.right {
				@include span(4 of 8);
				@include last;
			}
		}
	}
}
.form {
	._input-send-btn {
		input {
			width: calc(100% - 80px);
			float: left;
			border-top-left-radius: 2px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 2px;
			background: $inputBG;

			&._light {
				background: #f4f4e3;
			}
		}

		&.full-width{
			input {
				width: 80%;
			}
		}

		button {
			line-height: 36px;
			margin-top: 0;
			border-radius: 2px;
		}
	}

	@include susy-media($smallTablet) {
		._input-send-btn input {
			width: 240px;
			&.full-width{
				width: 80%;
			}
		}
	}
}

.form {
	._combined-input {
		display: flex;
	}
	input {
		margin: 0;

		&._invalid {
		    -moz-box-shadow:    inset 0 0 5px #FF0000;
		    -webkit-box-shadow: inset 0 0 5px #FF0000;
		    box-shadow:         inset 0 0 5px #FF0000;
		    box-sizing: border-box;
		    -moz-box-sizing: border-box;
		    -webkit-box-sizing: border-box;
		    border: 1px solid #f00;
		}
	}
	button,
	button.submit {
		margin: 0;
		//@include placeholder(rgba(84,83,37,0.5), 0.8em);
	}
	
	select {
		&._invalid {
		    -moz-box-shadow:    inset 0 0 5px #FF0000;
		    -webkit-box-shadow: inset 0 0 5px #FF0000;
		    box-shadow:         inset 0 0 5px #FF0000;
		    box-sizing: border-box;
		    -moz-box-sizing: border-box;
		    -webkit-box-sizing: border-box;
		    border: 1px solid #f00;
		}
	}
}

