section.trioBinoculars-wrap {
    position: relative;
    margin: 0;
    padding: 48px 0 24px;
    background: url('/img/assets/binoculars-plain-bg.jpg') no-repeat;
    background-size: cover;

    h2 {
        margin: 0;
        color: $darkGreen;
        text-align: center;
    }
}

.trioBinoculars {
    @include clearfix;
    margin-top: 24px;

    .item {
        background: $white;
        overflow: hidden;
        margin-bottom: 8px;
    }

    .title {
        padding: 16px 0;
        color: white;
        font-size: 18px;
        text-align: center;
        background: $titleBg;
    }

    img, .description, .price, .deposit, a {
        padding: 0 32px;
    }

    img {
        width: 100%;
    }

    .description {
        margin-bottom: 8px;
        text-align: left;
    }

    .price {
        margin: 0;
        color: $action;
        font-size: 45px;
        font-weight: bold;
        text-align: center;

        span{
            font-size: 15px;
        }

    }

    .deposit {
        margin: 0;
        line-height: 1em;
        text-align: center;
        font-size: 18px;
    }

    a {
        display: block;
        margin: 24px 16px 16px;
        padding: 8px;
        color: #fff;
        background: #f2994a;
        text-decoration: none;
        text-align: center;
    }

    @include susy-breakpoint($smallTablet) {
        .item {
            max-width: 460px;
            margin: 0 auto 8px;
        }
    }

    @include susy-breakpoint($tabletLandscape) {
        display: flex;
        max-width: 1000px;
        .item {
            overflow: initial;
        }

        .middle {
            z-index: 1;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

            .title {
                background: $footerGreen;
            }
        }
    }
}