.story-boxes {

	h3 {
		margin-top: 16px;
	}

	ul {
		margin-top: 26px;
	}

	li {
		margin-top: 8px;
	}

	img {
		display: block;
	}

	a {
		position: relative;
		display: block;
		text-align: center;

		&:hover {
			span {
				background: rgba(0,0,0,0.8);
			}
		} 
	}

	p {
		display: table;
		position: absolute;
		margin: 0;
		bottom: 0;
		width: 100%;
		text-align: center;
		font-size: 1em;
		line-height: 1.2em;
		color: $white;

		min-height: 40px;
		background: rgba(0,0,0,0.4);

		span {
			display: table-cell;
			vertical-align: middle;
			padding: 16px 8px;
		}
	}

	
}