.img-mash {

	margin: 50px auto !important;

	h1 {
		font-size: 50px;
		text-align: center;

		strong {
			font-size: 80px;
		}
	}

	&-navigation {
		text-align: center;
		li {
			display: inline-block;
		}

		a {
			position: relative;
			display: block;
			padding: 10px 14px;
			font-size: 18px;
			font-weight: bold;
			text-decoration: none;
			color: $darkGreen;
			&:hover {
				text-decoration: underline;
			}
		}

		li._current a {
			$triangle: 6px;
			background: $darkGreen;
			color: $white;
			text-decoration: none;
			&:after {
				content: "";
				position: absolute;
				left: calc(50% - #{$triangle});
				bottom: -$triangle;
				border-left: $triangle solid transparent;
				border-right: $triangle solid transparent;

				border-top: $triangle solid $darkGreen;
			}
		}
	}

	&-panel {
		position: relative;
		margin: 16px 0 0;
		min-height: 240px;
		visibility: hidden;

		ul {
			@include clearfix;

			li {
				position: relative;
				margin-top: 8px;
			}
		}

		p {
			display: table;
			position: absolute;
			margin: 0;
			bottom: 0;
			width: 100%;
			text-align: center;
			font-size: 1em;
			line-height: 1.2em;
			color: $white;

			min-height: 40px;
			background: rgba(0,0,0,0.4);

			span {
				display: table-cell;
				vertical-align: middle;
				padding: 16px 8px;
			}
		}

		img {
			display: block;
			width: 100%;

			+ p {
				visibility: hidden;
				opacity: 0;
				transition: opacity 0.3s, visibility 0.3s;
			}
		}

		img._loaded + p {
			visibility: visible;
			opacity: 1;
		}
	}

	@include susy-breakpoint($flabet) {

		&-panel {
			ul {

				li {
					width: calc(50% - 4px);
					float: left;
					margin-right: 8px;

					&:nth-child(2n+0) {
						margin-right: 0;
					}
				}
			}
		}
	}

	@include susy-breakpoint($tablet) {
		&-panel {
			ul {
				//cancel previous
				li:nth-child(2n+0) {
					margin-right: 8px;
				}

				li {
					width: calc(33.3% - 5.5px);
					float: left;
					margin-right: 8px;

					&:nth-child(3n+0) {
						margin-right: 0;
					}
				}
			}

			p {
				font-size: 1.1em;
				line-height: 1.2em;
			}
		}
	}



}