#email-pack-safari {
	position: relative;
	display: inline-block;

	.title {
		position: relative;
		display: inline-block;
		padding-right: 20px;
		float: left;
		color: $white;
		font-size: 18px;
		font-weight: bold;
		cursor: pointer;

		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&:after {
			content: "";
			position: absolute;
			top: 8px;
			right: 0;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 5px solid $white;
		}
	}

	.type {
		position: relative;
		width: 290px;
		display: block;
		float: left;
		margin-top: 12px;
	}

	.submit {
		width: 200px;
	}

	._checkbox {
		display: inline-block;
		width: 100%;
	}

	._checkbox {
		input {
			display: inline-block;
			margin-right: 8px;
		}

		span {
			color: $inputBG;
		}
	}
	._input-send-btn {
		input {
			margin-bottom: 10px;
		}
	}

	@include susy-media($laptop) {

	}
	@include susy-media($desktop) {
		.type {
			position: relative;
			width: 440px;
		}
	}
}