.top-nav{
	background: #000;
	height: 22px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	color: #FFF;
	z-index: 10;
	line-height: 22px;
	font-size: 12px;

	.col2 {
		text-transform: uppercase;
		font-size: 14px;
		color: $action;
		position: absolute;
		top: 128px;
		height: 22px;
		line-height: 22px;
		width: 100%;
		background: #000;
		a {
			color: $action;
		}
	}

	a.delivery {
		color: $action;
	}
	
	@include susy-breakpoint($mobLandscape) {
		.col2 {
			top: 77px;
		}
	}

	.col1, .col3 {
        @include span(6 of 12);
	}
	.col3 {
		@include last;
		font-weight: bold;
	}

	@include susy-breakpoint($tabletLandscape) {
		.col1, .col3 {
	        @include span(3 of 12);
		}
		.col3 {
			@include last;
		}
		.col2 {
			display: block;
			position: relative;
			top: auto;
			background: none;
			height: auto;
			line-height: auto;
        	@include span(6 of 12);
		}
	}
	
	a{
		padding: 0 3px;
		color: #FFF;
	}

	&-currencyChange {
		/* currency change dropdown */
		display: inline;

		ul {
			position: absolute;
			top: 24px;
			min-width: 130px;
			padding: 2px;
			border-radius: 2px;
			visibility: hidden;
			opacity: 0;
			height: 0;
			background: white;
			transition: opacity 0.3s;
			text-decoration: none;

			li {
				padding: 0;
				border-bottom: 1px solid $inputBGTop;
				cursor: pointer;
				background: $white;

				&:hover, &.current {
					background: $inputBG;
				}
			}

			&.show {
				min-width: 200px;
				max-height: 364px;
				left: 0;
				height: auto;
				visibility: visible;
				opacity: 1;
				z-index: 1;
			}

			a {
				display: block;
				padding: 8px 16px;
				text-align: left;
				height: auto;
				color: $darkGreen;

				&.current {
					text-shadow: 0 0 2px #cacaad;
					font-weight: bold;
				}

				&:hover {
					background: $inputBG;
					text-decoration: none;
				}

				span {
					float: right;
					font-size: 20px;
					position: relative;
					margin-top: -3px;
				}
			}
		}
	}
}
.menu {
	/* background: $inputBG; */
	/* ORIGINAL
	background: $inputBG;
	height: 56px;
	padding: 0 10px;
	*/

	background: rgba(0,0,0,0.4);
	height: 56px;
	padding: 0 12px;
	position: absolute;
	top: 22px;
	left: 0;
	width: 100%;
	z-index: 10;

	._mobileSearch & {
		background: rgba(0,0,0,0.9);
	}

	&:hover {
		background: rgba(0,0,0,0.9);
	}

	.container {
		height: 56px;
		._logo {
			display: none;
		}
		._mobileIcons & {
			._logo {
				position: relative;
				display: inline-block;
				width: 180px;
				margin-top: 16px;
				background: url('/img/assets/logo-mobile-the-safari-store.png') no-repeat 0 50%;
				background-size: contain;
				text-indent: -999em;
				z-index:1;
			}
		}
	}

	&-main {
		float: left;
		._mobileIcons & {
			display: none;
		}

		.no-js & {
			max-height: 56px;
			overflow: hidden;
			display: none;
		}

		@include susy-breakpoint($laptop) {
			.no-js & {
				display: block;
			}
		}
	}

	&-main > li {
		display: inline-block;
		margin-top: 0;
		float: left;
	}

	&-main > li.has-sub > a {
		padding: 17px 24px 17px 16px;

		&:after {
			content: "";
			position: absolute;
			right: 4px;
			top: calc(50% - 2px);
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 5px solid $white;
			transition: border-top 0.3s;
		}
	}

	&-main > li.active > a {
		/* color: $action; */
		color: #FFF;

		&:after {
			border-top: 5px solid $action;
		}
	}

	&-main > li > a {
		position: relative;
		display: block;
		padding: 17px 16px 18px;
		/*color: $footerGreen; */
		color: #FFF;
		font-size: 18px;
		font-weight: bold;
		text-decoration: none;

		&:hover {
			color: $action;

			&:after {
				border-top: 5px solid $action;
			}
		}
	}

	&-main > li:first-child a {
		padding-left: 0;
	}

	/* SUBCATEGORY */
	/* sub cat trigger */
	li.has-sub {
		&:hover > .menu-sub {
			display: block;

			li {
			}
		}
	}

	.menu-sub {
		position: absolute;
		margin-top: -1px;
		width: 100%;
		display: none;
		left: 0;
		/* background: $white; */
		/*background: rgba(0,0,0,0.5);*/
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.9+0,0.7+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		min-height: 60px; 
		z-index: 99;

		ul {
			float: left;
			padding: 16px;
			
		}

		li:first-child a,
		li.label {
			font-size: 18px;
			font-weight: bold;

			&:hover {

			}
		}

		li.label {
			margin-top: 15px;
		}

		a {
			display: block;
			padding: 4px 0;
			/* color: $footerGreen; */
			color: $white;
			text-decoration: none;

			&:hover {
				color: $action;
			}
		}

		.brand-box{
			width: 23%;
			padding: 4px;
			
			li:first-child{
				padding: 32px 0 32px 16px; 
				background-repeat: no-repeat;
    			background-size: 100%;
    			margin-top: 0px;
    			margin-right: 8px;
				border: 2px solid #FFF;
				a {
					font-size: 25px;
				}
			}
		}

	}

	/* RIGHT SIDE */

	&-right {
		position: absolute;
		width: 100%;
		top: 0;
		right: 0;
		text-align: right;

		.no-js & {
			display: none;
		}
	}

	/* SEARCH BLOCK */
	&-search, ._drop-down {
		position: relative;
		width: 300px;

		input {
			float: left;
			width: 224px;
		}

		button {
			text-indent: -100em;
			/*background: url('../../assets/search.svg') $action no-repeat 50%;*/
			/*background: url('/img/assets/search.svg') $action no-repeat 50%;*/
			background: url('/img/assets/search.svg') $action no-repeat 50%;
			width: 76px;
			height: 40px;
			line-height: 36px;
			border: none;
			border-radius: 0;
			overflow: hidden;
			font-size: 14px;
			color: $white;
		}
	}

	@include susy-breakpoint($tabletLandscape) {
		&-right {
			width: 300px;
		}
	}
}