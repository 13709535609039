.buy-together-and-save {
	
	ul {
		&.products {
			li {
				height: 180px;
				width: 150px;
				text-align: center;
				float: left;

				&:not(:first-child){
					width: 175px;
					
					span.quantity { 
						padding-left: 35px;
					}

				 	&:before {
						content: '+';
						height: 150px;
						line-height: 150px;
						font-size: 30px;
						vertical-align: top;
				 		padding-right: 20px;
					}
				}

				img {
					height: 150px;
				}

				span.quantity {
					text-align: center;
					font-weight: bold;
					display: block;
				}
			}
		}
		margin-bottom: 20px;
	}

	.total-price {
		float: left;
		padding: 20px;
	}

	.submit {
		margin-top: 10px !important;
	}

	.product-list {
		.price {
			padding-left: 5px;
		}
	}

}