.section-box{
	border: #FFF solid 5px;
	position: relative;
	display: block;

	h2, p {
		position: absolute;
		width: 90%;
		left: 5%;
		text-decoration: none;
		color: #FFF;
	}

	h2 {
		top: 10px;
		text-align: center;
		font-size: 1.8em;
	}

	p {
		bottom: 10px;
	}

	/* gradient on top of image */
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.8+0,0.2+100 */
		background: -moz-linear-gradient(top,  rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.6) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}

}