/* SEEMS LIKE NOT WORKING ON SPECIFIC ELEMENT, ONLY GLOBALLY
@mixin placeholder($color, $size) {
	&::-webkit-input-placeholder {
		color: $color;
		font-size: $size;
	}

	&:-moz-placeholder { !* Firefox 18- *!
		color: $color;
		font-size: $size;
	}

	&::-moz-placeholder {  !* Firefox 19+ *!
		color: $color;
		font-size: $size;
	}

	&:-ms-input-placeholder {
		color: $color;
		font-size: $size;
	}

	&::placeholder {
		color: $color;
		font-size: $size;
	}
}*/
