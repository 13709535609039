h1, h2, h3, h4, h5, h6 {
	/*font-family: $header-font-lora;*/
}

h1 {
	font-size: 30px;
	margin: 22px 0 0;
	font-weight: normal;
	color: $darkGreen;
	text-align: center;

	@include susy-breakpoint($smallTablet) {
		text-align: left;
	}

	span{
		font-size: 16px;
		color: $subtleOnDarkGreen;
		line-height: 18px;
		display:block;
		font-style: italic;
	}
}

h2, h3 {
	font-weight: normal;
	margin: 24px 0;
	color: $darkGreen;
	&.right {
		text-align: right;
	} 
	&.strong{
		font-weight: bold;
	}
}

h3 {
	font-size: 26px;
	&.center {
		margin: 22px 0 22px;
	}
}

h4 {
	font-size: 18px;
	font-weight: bold;
	font-family: $text-font-alegreya;
	color: $darkGreen;
 	
 	&.lead {
 		font-size: 28px;
 		text-transform: uppercase;
 		text-align: center;
 		margin-top: 40px;
 	}
}

h5 {
	font-size: 16px;
	font-weight: bold;
	font-family: $text-font-alegreya;
	color: $darkGreen;
}

._spotlight {
	padding-top: 24px;
	font-size: 40px;

	em {
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		text-align: center;

		@include susy-breakpoint($tabletLandscape) {
			text-align: left;
			font-size: 56px;
		}
	}
}

.rrp{
	font-size: 18px;
	font-weight: bold;
	padding-left: 10px;
	color: $lightGreen;
	s {
		text-decoration: line-through;
	}
}
a {
	transition: color 0.3s;
	color: $action;
	cursor: pointer;
	text-decoration: none;

	&.dark-green {
		color: $darkGreen;
	}
	
	&.block{
		display: block;
	}
	&.small {
		font-size: 0.9em;
	}
}

p {
	line-height: 1.4em;
	strong {
		font-weight: bold;
	}
	&.header {
		font-size: 24px;
		margin: 24px 0;
	}
	&.lead {
		font-size: 1.3em;
	}
}

body._bg-dark{
	p, li, blockquote {
		color: $darkGreen;
	}
}

.center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}

/*
p.center,
h4.center {
	text-align: center;
}*/

.note {
	font-size: 18px;
	color: $lightGreen;
	line-height: 20px;
}

p.notice {
	font-weight: 600;
	font-size: 18px;
	padding: 5px 0;
	color: #FFF;
}

p.notice_white{
	font-weight: 600;
	font-size: 18px;
	padding: 5px;
	text-align: center;
	border: 1px solid #f00;
}

ul.basic {
	margin-top: 10px;
	margin-bottom: 20px;

	li {
        margin: 0 0 5px 25px;
        font-size: 17px;
        list-style: circle;

        
        a {
        	color: black;
	        &:hover,
	        &:focus {
	        	color: inherit;
	        }	
	    }
	}

}

ol {
	margin: 0 14px;

	li {
		margin-top: 8px;
	}
}

p.postcode-notice{
	margin-top: 2px;
}

.side-note{
	display: block;
	text-align: right;
	font-size: 0.8em;
}

dl.normal {
	dt {
		font-weight: bold;
	}
	dd {
		margin-bottom: 15px;
	}
}

blockquote {
	color: black;
    font-style: italic;
    margin: 10px 0;
    font-size: 1.4em;

    &.center{
    	margin: 50px 0;
    }

    &.narrow{
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &.very-narrow{
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    &:before,
    &:after {
        color: $darkGreen;
        content: open-quote;
        font-size: 3em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
    &:after {
        content: close-quote;
    }
}