
#share-packing-list {
	display: block;
	overflow: hidden;
}

.on-top {
	position: relative;
	display: inline-block;
}

.packing-list {
	h3 {
		margin-bottom: 5px;
	}
	._checkbox {
		input {
			margin-top: 2px;
		}
		span {
			font-size: 20px;
		}
	}
	&-item {
		padding: 10px;
		h5 {
			margin: 0;
			font-weight: normal;
			overflow: hidden;
			font-size: 16px;
			line-height: 16px;

			@include susy-breakpoint($smallTablet) {
				font-size: 18px;
				line-height: 18px;
			}
			&:before {
				content: "\25A2";
				padding-right: 5px;
				font-size: 14px;
				line-height: 14px;

				@include susy-breakpoint($smallTablet) {
					font-size: 16px;
					line-height: 16px;
				}
			}

			a {
				&.show-more {
					float: right;
					font-size: 16px;
					margin-top: 3px;
				}
			}
		}
		div {
			margin-top:5px;

			a.submit {
				margin-top:10px;
			}
		}

		&:nth-child(odd) {
			background: $inputBGTop;
		}

	}
}

.packing-list-items {
	padding-left: 20px;
}

.packing-list-content {
	text-align: justify;
}

.packing-list-extras {
	 .left, .right {
		padding: 0 15px;
	}
}

.packing-list-wide {
	#email-pack-safari {
		display: block;	
	}
}