.cookie-notice {
	
	background: $footerGreen;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 99999;
	height: 100px;

	p {
		padding: 10px;
		color: $whiteOnDarkGreen;
		font-size: 14px;
		margin: 0;
	}

	a {
		background: $whiteGreen3;
		float: right;
		margin: 10px 20px 10px 20px;
		width: 150px;
		height: 18px;
		line-height: 18px;
		text-align: center;
		font-size: 14px;
		color: $darkGreen;
	}

}


@include susy-breakpoint($smallTablet) {
	.cookie-notice {
		height: auto;
	}
}