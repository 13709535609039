$tss-size: 40px !default;
$tss-spinner-color: #fff;

.tss-spinner-pulse {
    position: absolute;
    width: $tss-size;
    height: $tss-size;
    top: calc(50% - #{$tss-size/2});
    left: calc(50% - #{$tss-size/2});
    background-color: $tss-spinner-color;
    border-radius: 100%;
    animation: tss-pulse 1.0s infinite ease-in-out;
}

@keyframes tss-pulse {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}