.contact-us,
.friday-quiz,
.generic {
	@extend %row;

	&-form {
		@include span(12 of 12);

		label, span {
			display: block;
		}

		span {
			margin-bottom: 4px;
		}

		label {
			margin-top: 24px;
		}

		input, select, textarea {
			width: 100%;
			background: $inputBG;
			border: none;
			border-radius: 2px;
		}

		input {
			height: 40px;
			padding: 0 8px;
			line-height: 40px;
		}

		select {
			height: 40px;
		}

		textarea {
			height: 120px;
			padding: 4px 8px;
		}

		button[type=submit] {
			background: $action;
			margin-top: 16px;
			min-width: 120px;
			height: 40px;
			padding: 0 16px;
			float: right;
			line-height: 40px;
			color: $white;
			border: none;
			border-radius: 2px;
		}

		&.center {
			width: 60%;
			margin-left: 20%;
			text-align: left;
		}

	}

	aside {
		@include span(12 of 12);
		@include last;
	}

	/* MEDIA QURIES */

	@include susy-media($tablet - 1) {
		&-form {
			@include span(6 of 12);
		}

		aside {
			@include span(6 of 12);
			@include last;
		}
	}

	@include susy-breakpoint($laptop) {
		&-form {
			@include span(8 of 12);

			label[for=email],
			label[for=name] {
				@include span(6 of 12);
			}

			label[for=reason],
			label[for=telephone] {
				@include span(6 of 12);
				@include last;
			}

			label[for=comment] {
				@include span(12 of 12);
			}
		}

		aside {
			@include span(4 of 12);
			@include last;
		}
	}
}