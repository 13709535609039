
.film-strip {

  &-wrap {
      position: relative;
      overflow: hidden;

      @include susy-breakpoint($smallTablet) {
          margin-left: 40px;
          margin-right: 40px;
      }
  }

  ul {
    position: relative;
    overflow: hidden;
  }

  li {
    width: 50%;
    height: 315px;
    @include susy-breakpoint($smallTablet) {
        height: 245px;
    }
    padding: 0 20px;
    float: left;

    .img-area{
        text-align: center;
        img {
            max-height: 220px;
            @include susy-breakpoint($smallTablet) {
                max-height: 150px;
            }
        }
    }
    
    h3 {
        font-size: 16px;
        margin-top: 6px;
    }
  }

  &-nav {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    cursor: pointer;
    top: calc(50% - 22px);
    background: url('/img/assets/arrow-film.svg') $inputBG 50% no-repeat;
    transition: background 0.3s, opacity 0.3s;
    z-index: 2;
    opacity: 0;
    visibility: hidden;

    &:hover {
      background: url('/img/assets/arrow-film-active.svg') $action 50% no-repeat;
    }

    &._left {
      left: 0px;
      transform: rotate(180deg);
    }

    &._right {
      right: 0px;
    }

    &._last {
      cursor: default;
      background: url('/img/assets/arrow-film.svg') $grey 50% no-repeat;
      &:hover {
        background: url('/img/assets/arrow-film.svg') $grey 50% no-repeat;
      }
    }
  }

  @include susy-breakpoint($smallTablet) {
    &-wrap {
      height: 145px;
    }

    ul {
      position: absolute;
      transition: transform 0.3s;
    }

    li {
      width: 234px;
      height: 145px;
      margin: 0;
      float: left;
    }
  }

  // extending to related products
  &.related-products {

    padding: 0 10px;

    @include susy-breakpoint($smallTablet) {

      padding: 0;

      .container {
          border-top: 1px solid $inputBG;
      }

      .film-strip-wrap {
          height: 300px;
          margin-top: 10px;
      }

      li {
          position: relative;
          width: 188px;
          height: 300px;
      }
    }
  }
}
