.confirm_ip {

	height: 175px;
	width: 100%;
	background: $seeThruWhiteWithGreenTone;
	box-shadow: 0 2px 0 $subtleOnDarkGreen;
	display: block;

	form {
		width: 90%;
		margin-left: 5%;
		float: none;

		h4{
			margin: 0;
			padding: 10px 0 8px;
		}

		label {

			width: 100%;
			margin: 0 0 5px;
			display: block;

			select {
				font-size: 14px;
				width: 100%;
			}
		}

		button {
			width: 100%;
			margin: 0 !important;
			float: none !important;
		}
	}

	
	&._full-height {
		position: absolute;
		top: 117px;
		width: 100%;
		left: 0;
		z-index: 14;
	}

	@include susy-breakpoint($tablet) {
		
		height: 92px;
		

		form {
			width: 60%;
			margin-left: 20%;
			label {
				margin: 0;
			}
		}

		&._full-height {
			top: 220px;
			width: 50%;
			left: 25%;
			border-radius: 10px;

			form {
				width: 90%;
				margin-left: 5%;
			}
		}
	}
}