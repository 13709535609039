.sagepay-logo{
	float:right;
	margin-top: 10px;
}
.delivery_address,
.billing_address {
	position: relative;
}


.shopping-basket {

	.max-input-width {
		max-width: 450px;		
	} 

	.item-group {
		margin-bottom: 24px;
		padding: 4px 4px 8px 4px;
		box-shadow: 0 0 2px 0 rgba(206, 206, 128, 0.8);
		border-radius: 3px;

	}

	.label-select {
		overflow: hidden;
		margin-bottom: 3px;
		span {
			float: left;
			width: 80px;
			line-height: 30px;
			height: 30px;
		}
		select {
			float: left;
			clear: right;
			width: 180px;
			height: 30px;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	.title {
		padding-bottom: 8px;

		h1, p {
			margin: 0;
		}

		h1 {
		}

		.price,
		.quantity {
			display: none;
		}
	}

	.postcode-notice {
		text-align: center;
		font-size: 20px;
		margin: 20px 0 16px;
		display: block;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}


	.item {
		padding-top: 15px;

		&:not(:first-child) {
			border-top: 1px solid $inputBG;
		}

		h4{
			margin: 0 0 5px;

			span {
				display:block;
				font-size: 0.8em;
				color: #999;
			}
		}
		
		&._alert {
			background: $whiteOnDarkGreen;
		    box-sizing: border-box;
		    -moz-box-sizing: border-box;
		    -webkit-box-sizing: border-box;
		    border: 2px solid #f00;
		}
		.thumb {
			max-width: 116px;
			float: left;

			img {
				max-width: 100px;
			}
		}

		.description {
			min-height: 148px;
			padding-right: 8px;
			padding-left: 116px;

			h3 {
				margin-top: 0;
			}

			.in-stock {
				color: $green;
				text-align: right;
			}
		}

		.price {
			position: relative;
			top: -12px;
			float: right;
			font-size: 24px;

			/*flex: 1 70px;*/
			padding-right: 8px;

			h4 {
				margin-top: 0;
				color: $action;
			}
		}

		.quantity {
			flex: 1 70px;

			select {
				position: relative;
				top: -16px;
				max-width: 75px;
			}

			.removeFromBasket {
				font-size: 12px;
				margin-top: -5px;
				color: red;
				display: block;
			}
		}

		.link-size-chart {
			float: left;
			font-size: 14px;
			line-height: 30px;
			margin-left: 5px;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

	}

	.shipping-options {
		padding: 0 24px;
		border-top: 1px solid $inputBG;

		.shipping-option {
			cursor: pointer;
			margin-top: 5px;
			border: 2px solid $pageBgGreen;
			background: $whiteGreen;
			border-radius: 10px;
			padding: 24px;
			text-align: center;
			color: #000;
			
			&._active {
				background: $pageBgGreen;
				border-color: $pageBgGreen1;
			}
			span {
				font-size: 16px;
				display: block;
				
				&._price {
					font-size: 20px;
				}
				&._days {
					margin-top: 3px;
					font-size: 14px;
				}
			}

			&:hover {
				border-color: $pageBgGreen1;
				background-color: $whiteGreen1;
			}
		}
	}

	.postage {
		max-width: 450px;

		label {
			margin-top: 18px;
			color: $darkGreen;

			span {
				display: block;
				padding-bottom: 9px;
			}
		}
		/* Moved to globals */
		/*.prices {
			margin: 24px 0 0;
			font-size: 1.2rem;

			dt {
				min-width: 80px;
				float: left;
				clear: left;
				text-align: left;
				font-weight: bold;
			}
			dt::after {
				content: ":";
			}
			dd {
				margin: 0 0 0 88px;
				padding: 0 0 0.5em 0;

				&.action {
					color: $action;
				}
			}
		}*/
		.submit {
			display: block;
			text-align: center;
		}
	}

	@include susy-media($smallTablet) {



		/* TITLE PART */
		.title {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			h1 {
				flex: 5 324px;
			}

			.price,
			.quantity {
				display: block;
			}

			.price {
				flex: 1 70px;
			}
			.quantity {
				flex: 1 70px;
			}
		}

		/* ITEMS */
		.item {
			display: flex;
			align-items: flex-start;
			padding-top: 36px;
			justify-content: space-between;

			.thumb {
				flex: 1 116px;
				padding-bottom: 24px;
				float: none;
			}

			.description {
				flex: 5 184px;
				min-height: inherit;
				padding-left: inherit;

				.in-stock {
					text-align: left;
				}
			}

			.price {
				top: 0;
				float: none;
				font-size: inherit;

				flex: 1 70px;
			}

			.quantity select {
				top: -10px;

			}
		}

		/* End of $smallTablet MD */
	}

	@include susy-media($tablet) {
		.items {
			.thumb {
				margin-left: 24px;
			}
		}
	}

	@include susy-media($tabletLandscape) {
		.items {
			@include span(8 of 12);
			padding-right: 8px;
		}

		.postage {
			@include span(4 of 12);
			@include last;
			max-width: initial;
			padding-left: 8px;
		}

		.shipping-options {

			.right {
				text-align: left;
				h3 {
					font-size: 1.17em;
				}
			}
		}
		/* End of $tabletLandscape MD */
	}
}