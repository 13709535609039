$susy: (
  columns: 12,  // The number of columns in your grid
  gutters: 1/8, // The size of a gutter in relation to a single column
);
.col_4_4_4 {

  overflow: hidden;

  ._short {
    max-width: 300px;
    margin: 0;
  }

  .col1, .col2, .col3 {
    @include span(12 of 12);
  }

  @include susy-media($smallTablet) {
    &._featured {
      .col1 {
        @include span(12 of 12);
      }

      .col2, .col3 {
        @include span(6 of 12);
      }

      .col3 {
        @include last;
      }
    }
  }

  @include susy-media($tabletLandscape) {
    ._short {
      max-width: 1200px;
      margin: auto;
    }

    .col1, .col2, .col3 {
      @include span(4 of 12);
    }

    .col3 {
      @include last;
    }

    &._featured {
      .col1 {
        @include span(12 of 12);
      }

      .col2, .col3 {
        @include span(6 of 12);
      }

      .col3 {
        @include last;
      }
    }
  }

  @include susy-media($desktop) {
    .col1, .col2, .col3 {
      @include span(4 of 12);
    }
    .col3 {
      @include last;
    }

    &._featured {
      .col1 {
        @include span(6 of 12);
      }

      .col2, .col3 {
        @include span(3 of 12);
      }

      .col3 {
        @include last;
      }
    }
  }
}