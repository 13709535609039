.col_6_6 {

  	overflow: hidden;
  	
	.left, .col1 {
		@include span(12 of 12);
	}
	.right, .col2 {
		@include span(12 of 12);
	}


	@include susy-media($tablet - 1) {
		.left, .col1 {
			@include span(6 of 12);
		}
		.right, .col2 {
			@include span(6 of 12);
			@include last;
		}
	}
}