.flexter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
        padding: 8px;
        margin: 16px 8px 0;
        width: 240px;
    }

    &.tourOperator > div {
        background: white;
    }
}