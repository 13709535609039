$triangleSizeNormal: 17px;
$triangleSizeBig: 23px;

@mixin triangle($size) {
  right: -16px;
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;
  border-left: 16px solid $whiteGreen1;
}

.products {
    h2 {
        margin: 12px 0 10px;
        color: $darkGreen;
        font-size: 22px;

        a {
            display: inline-block;
            margin-left: 5px;
            position: relative;
            font-weight: normal;
            font-style: italic;
            font-size: 18px;
            text-decoration: underline;

            &:after {
                content: "";
                position: absolute;
                right: -20px;
                top: calc(50% - 2px);
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 5px solid $orange;
                border-bottom: 0;
            }
            &._active {
                &:after {
                  top: auto;
                  bottom: calc(50% - 2px);
                  border-top: 0;
                  border-bottom: 5px solid $orange;
              }
            }
        }
    }

    .packing-advice {

        display: none;
        margin: 5px 0 10px;
        padding: 10px;
        font-size: 15px;
        text-align: left;
        transition: display 0.5;
        border: 1px dashed $lightGreen;

        &._active {
           display: block;
        }
        ul {
            margin:0;
            li {
                margin: 0 0 5px 15px;
                font-size: 15px;
                list-style: circle;
            }
        }

        p {
          margin-bottom: 0;
        }

    }
}


@include susy-media($smallTablet) {
    .toggle-filters {
        display: none !important;
    }
}

.filter {

    display: none;

    @include susy-media($smallTablet) {
        display: block;
        width: 90%;
    }
      

    h3 {
        padding-left: 8px;
        color: $darkGreen;
        font-weight: bold;
        margin: 12px 0 8px;
    }

    form {
        padding-bottom: 8px;
        border-bottom: 1px solid $whiteGreen1;
    }
    
    ul {
        padding-bottom: 8px;
        border-bottom: 1px solid $whiteGreen1;

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    li {
        transition: opacity 0.3s;
    }

    li, li.hide {
        height: 0;
        opacity: 0;
        visibility: hidden;
    }

    li.show, li.hide.show {
        height: auto;
        opacity: 1;
        visibility: visible;
        display: block !important;
    }

  a {
    position: relative;
    display: block;
    height: 32px;
    margin-right: -5px;
    text-decoration: none;
    color: $darkGreen;
    padding: 10px 5px;
    font-size: 14px;
    line-height: 14px;

    span {
      display: none;
    }
    &.showMore {
      background: none;
      color: $action;

      &:hover {
        text-decoration: underline;
        background: none;
      }

      &:after {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
    }

    &._active {
      background: $whiteGreen1;
      font-weight: bold;

      span {
        float: right;
        display: block;
      }

      &:after {
        @include triangle($triangleSizeNormal);
      }
    }

    &:hover {
      background: $whiteGreen1;

      &:after {
        @include triangle($triangleSizeNormal);
      }
    }

    &._not-available {
      background: none;
      color: $grey;

      &:hover {
        background: none;

        &:after {
          display:none;
        }
      }

      &:after {
        display: none;
      }
    }
  }


}