.col_3_3_3_3 {

  	overflow: hidden;
  	
	.col1, .col2, .col3, .col4 {
		@include span(12 of 12);
	}

	@include susy-media($smallTablet) {
		.col1, .col2, .col3, .col4 {
			@include span(6 of 12);
		}
		.col2, .col4 {
			@include last;
		}
	}

	@include susy-media($tabletLandscape) {
		.col1, .col2, .col3, .col4 {
			@include span(3 of 12);
		}

		.col4 {
			@include last;
		}
	}
}