.related-products{

	/*ul{
		overflow: hidden;

		li {
			float: left;
			width: 200px;

			img{
				width: 100%;
			}
		}
	}*/
}