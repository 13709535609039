$frameHeight: 650px;

/* TODO: show when JS is loaded */

.image-carousel {

  &-wrap {
    position: relative;
    height: $frameHeight;
  }

  &-nav {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    cursor: pointer;
    top: calc(50% - 22px);
    //background: url('/img/assets/arrow-film.svg') rgba(255, 255, 255, 0.3) 50% no-repeat;
    background: url('/img/assets/arrow-film-active.svg') $action 50% no-repeat;
    transition: background 0.3s, opacity 0.3s;
    z-index: 2;
    opacity: 0.8;

    .no-js & {
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      //background: url('/img/assets/arrow-film-active.svg') $action 50% no-repeat;
      opacity: 1;
    }

    &._left {
      left: 40px;
      transform: rotate(180deg);
    }

    &._right {
      right: 40px;
    }

    &._last {
      opacity: 0.3;
      cursor: default;
    }
  }

  ul {
    position: absolute;
    height: 100%;
    transition: transform 0.5s;

    li {
      position: relative;
      margin-right: 10px;
      height: 100%;
      float: left;
    }

    div {
      position: relative;
      height: 100%;
      background: no-repeat 50%;
      background-size: cover;
    }

    p {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 4px;
      color: $white;
      text-align: center;
      background: rgba(0,0,0,0.5);
    }
  }

  /**
    This will make 0 - 640 static images + description on web, above 640 will be image carousel
  */
  @media (max-width: $tablet) {
    &-wrap {
      height: auto !important;
    }
    ul {
      position: relative;

      div {
        height: 300px;
      }

      li {
        margin-top: 24px;
        float: none;
      }

      div {
        height: 300px;
      }

      p {
        position: relative;
        background: rgba(0, 0, 0, 0.8);
      }
    }

    &-nav {
      display: none;
    }
  }

  @include susy-breakpoint($tablet) {
    display: block;
  }
}