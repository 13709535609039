$susy: (
	columns: 12, // The number of columns in your grid
	math: fluid,
	output: float,
	gutters: 0.5,
	gutter-position: after
);

// media query sizes
$mob: 0;
$mobLandscape: 380px;
$flabet: 400px;
$smallTablet: 480px;
$flexSingleToTwo: 540px;
$tablet: 640px;
$tabletLandscape: 768px;
$laptop: 1024px;
$desktop: 1200px;
$elephant: 1600px;