.price-box {
	border: 5px solid $white;
	padding: 10px;
	margin: 10px;

	&-symbol{
		display: block;
		font-size: 2em;
		color: $darkGreen;
		text-align: center;
	}
}

.price-box._active {
	margin: 5px;
	padding: 15px;
}