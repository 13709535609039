/**
	For home page we use class _home
*/

.top-banner {
	
	min-height: 200px;
	padding-top: 110px;
	background-color: $whiteGreen3;
	position: relative;
	width: 100%;
	padding: 90px 0 0;
	clear: both;
	overflow: hidden;

	&.is-checkout {
		margin-top: 0;
		min-height: 150px;
	}

	&._has-affiliate-logo {
		min-height: 400px;
	}
	
	@include susy-breakpoint($smallTablet) {

		min-height: 270px;
		margin-top: auto;


		&._has-content {	
			min-height: 400px;
		}

		&._has-affiliate-logo {

			min-height: 350px;
			
			&._has-content {	
				min-height: 530px;
			}
		}
	}

	h1 {
		color: $white;
		a {
			text-decoration: none;
			color: $white;
			&:hover,
			&:active {
				text-decoration: none;
				color: $white;
			}
		}
	}

	&-logo {
		 
		margin-left: 50%;
		width: 350px;
		height: 71px;
		display: none;

		@include susy-breakpoint($laptop) {
			display: block;
		}

		img {
			width: 100%;
			margin-left: -175px;

			._mobileIcons { 
				width: 300px;
				margin-left: 25px;
			}
		}
	}

	&-personal,
	&-links {
		float: left;
		text-align: left;
		display: none;

		@include susy-breakpoint($laptop) {
			display: block;
		}

		a {
			border: none;
			color: $white;
			font-size: 18px;
			text-decoration: none;
			position: relative;
			padding-right: 20px;
 			
 			&:hover {
 				text-decoration: underline;
 			}
			
			&._sign-in {
				background: url('/img/assets/icn-lock-white.svg') no-repeat 0;
				background-size: contain;
				padding-left: 25px;
				padding-right: 20px;
			}

			&._basket {
				background: url('/img/assets/icn-cart-white.svg') no-repeat 0;
				background-size: contain;
				padding-left: 25px;
				padding-right: 0;

				span._amount {
					position: absolute;
					border-radius: 50%;
					background: $action;
					color: #fff;
					top: 10px;
					left: 9px;
					width: 18px;
					height: 18px;
					padding-top: 2px;
					text-align: center;
					font-size: 11px;
					text-indent: initial;
				}

				&._hide span._amount {display:none;}
			}
		}

		._mobileIcons & {
			display: none;
		}
	}

	.rental-banner-price {
		text-align: right;
		color: #FFF;
		padding-top: 10px;
	}

	&-personal {
		float: right;
		text-align: right;
		right: 10px;
	}

	.container {
		height: 60px;
		@include susy-breakpoint($laptop) {
			height: 100%;
		}
		z-index: 3;
	}

	&-content {
		padding: 10px 10px 0;
		text-align: center;

		.title {
			margin: 0;
			font-size: 30px;
			color: $white;
			font-weight: bold;
			transition: font-size .3s;
			text-align: center;
			text-shadow: 2px 2px 4px #000000;
		}

		p {
			display: none;
			max-height: 328px;
			color: $white;
			font-size: 16px;
			overflow: hidden;
			text-align: center;
			margin-top: 4px;
			a {
				color: $white;
				text-decoration: none;
			}
			text-shadow: 2px 2px 4px #000000;
		}

		@include susy-breakpoint($smallTablet) {

			text-align: center;
			width: 100%;
			max-width: 100%;

			.title {
				font-size: 36px;
			}

			p {
				font-size: 18px;
				padding: 2px;
				max-width: 900px;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

.partner-top-banner-logo {
		
	margin-left: 50%;
	width: 350px;
	display: block;
	padding-top: 40px;

	@include susy-breakpoint($smallTablet) {
		display: block;
		padding-top: 0;
	}

	.partner-logo {
		width: 100%;
		margin-left: -175px;
		height: 122px;
		display: block; 
	}

	.tss-logo {
		width: 191px;
		margin-left: -95px;
		height: 52px;
		display: block;
		margin-top: 10px;
	}
}

.top-banner-images {

	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;

	p:hover {
		a {
			text-decoration: underline;
		}
	}

	a {
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	
	li {
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		padding: 0;
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity 0.75s linear;
		z-index: 1;

		.overlay {
			/* gradient on top of background image */
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.8+0,0.2+100 */
			background: -moz-linear-gradient(top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		}

		&:first-child {
		  	visibility: visible;
		  	opacity: 1;
		  	z-index: 2;
		}
		
		@include susy-breakpoint($smallTablet) {
			.overlay {
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.8+0,0.2+100 */
				background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.2) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.2) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			}

		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}

	&-content {
		padding: 180px 30px 20px;
		text-align: center;

		.title {
			margin: 0;
			font-size: 20px;
			color: $white;
			font-weight: bold;
			transition: font-size .3s;
			text-align: center;
			text-shadow: 2px 2px 4px #000000;
		}

		p {
			max-height: 328px;
			color: $white;
			font-size: 16px;
			overflow: hidden;
			text-align: center;
			margin-top: 4px;
			margin-bottom: 0px;
			a {
				color: $white;
				text-decoration: none;
			}
			text-shadow: 2px 2px 4px #000000;
		}

		@include susy-breakpoint($smallTablet) {

			text-align: center;
			width: 100%;
			max-width: 100%;
			padding-top: 130px;

			.title {
				font-size: 36px;
			}

			p {
				font-size: 20px;
				padding: 2px;
				max-width: 800px;
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}

		@include susy-breakpoint($laptop) {
			padding-top: 190px;
		}
	}
}

._has-affiliate-logo .top-banner-images-content {
	padding-top: 340px;
}

.top-banner-controls {

    display: table;
    margin: -30px auto 20px;
	z-index: 5;
	position: relative;
	visibility: hidden;

	@include susy-breakpoint($laptop) {
		visibility: visible;
	}

	ul {
		list-style: none;
		
		li {
			float: left;

			a.circle {
				line-height: 30px;
				height: 10px;
				width: 10px;
				display: block;
				margin: 3px 6px 0;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				background: rgba(255,255,255,0.8);
			}

			&._active  {
				a.circle {
					height: 16px;
					width: 16px;
					margin: 0px 6px;
					-webkit-border-radius: 8px;
					-moz-border-radius: 8px;
					border-radius: 8px;
					background: $lightGreen;
				}
			}

		}
	}
}