$susy: (
  columns: 12,  // The number of columns in your grid
  gutters: 1/8, // The size of a gutter in relation to a single column
);

.col_2_5_5 {

    overflow: hidden;

    .col1, .col2, .col3 {
        @include span(12 of 12);
    }

    @include susy-media($smallTablet) {

        .col1 {
            @include span(2 of 12);
        }

        .col2, .col3 {
            @include span(5 of 12);
        }

        .col3 {
            @include last;
        }
    }

}