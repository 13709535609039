.col_8_4 {

  	overflow: hidden;
  	
	> .left {
		@include span(12 of 12);
	}
	> .right {
		@include span(12 of 12);
	}


	@include susy-media($tablet - 1) {
		> .left {
			@include span(7 of 12);
		}
		> .right {
			@include span(5 of 12);
			@include last;
		}
	}

	@include susy-media($laptop) {
		> .left {
			@include span(8 of 12);
		}
		> .right {
			@include span(4 of 12);
			@include last;
		}
	}
}