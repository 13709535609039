$susy: (
  columns: 12,  // The number of columns in your grid
  gutters: 1/8, // The size of a gutter in relation to a single column
);
.col_3_6_3 {

    overflow: hidden;

    .col1, .col2, .col3 {
        @include span(12 of 12);
    }

    @include susy-media($smallTablet) {


        .col1, .col3 {
            @include span(3 of 12);
        }

        .col2 {
            @include span(6 of 12);
        }

        .col3 {
            @include last;
        }
    }

}