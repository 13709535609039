.footer {

	p.pre-footer {
		text-align: center;
		margin: 0;
		padding: 5px 0;
		color: #FFF;
		width: 70%;
		margin-left: 15%;

	}

	margin-top: 24px;
	background: $footerGreen;
	@extend %row;

	.container {
		max-width: 320px;
	}

	// fonts
	h5, li a {
		color: $whiteOnDarkGreen;
	}

	p, li {
		color: $subtleOnDarkGreen;
		font-size: 18px;
	}

	h5 {
		margin: 45px 0 20px 0;
		font-size: 30px;
		font-weight: bold;
	}

	h4 {
		font-size: 24px;
		font-weight: bold;
		color: $subtleOnDarkGreen;
	}

	a {
		text-decoration: none;
		//TODO: what is for hover animation?
		&:hover {
			text-decoration: underline;
		}
	}

	// layout

	&-store-details {
		//background: yellow;
		@include span(12 of 12);

		li {
			line-height: 24px;
		}

		img {
			display: block;
			margin-top: 15px;
		}
	}

	&-sitemap {
		//background: green;

		h4 {
			margin: 52px 0 20px 0;
		}

		// Categories
		> li {
			//background: salmon;
			line-height: 24px;

			&:last-child {
				//background: lightgoldenrodyellow;
			}
		}

		a {
			font-weight: bold;
		}
	}

	&-join-us {
		li {
			line-height: 32px;
		}
	}

	@include susy-media($tablet $laptop - 1) {
		.container {
			max-width: inherit;
		}

		&-store-details {}

		&-sitemap {
			@include span(8 of 8);

			// Categories
			> li {
				@include span(4 of 8);

				&:nth-child(even) {
					@include last;
				}
			}
		}
	}

	@include susy-breakpoint($laptop) {
		.container {
			max-width: 1200px;
		}

		&-store-details {
			@include span(3 of 12);
		}

		&-sitemap {
			@include span(9 of 12);
			@include last;

			// Categories
			> li {
				@include span(3 of 12);

				&:last-child {
					@include last;
				}
			}
		}
	}
}