.product-item {

	padding: 20px 10px;
	
	@include susy-media($laptop) {
		padding-left: 0;
		padding-right: 0;
	}

	h1 {
		margin: 0;
		span{
			color: black;
		}
		font-size: 20px;
		text-align: center;
		padding: 15px;
	}
	.brand {
		margin: -13px 0 20px;
		p {
			margin: 0;
			color: #666;
		}
	}
	p.lead {
		text-align: justify;
		font-size: 16px;
	}
	
	@include susy-media($smallTablet) {
		h1 {
			font-size: 24px;
			text-align: left;
			padding: 0 0 15px;
		}

		p.lead {
			font-size: 1.3em;
		}
	}

	.left, .right {
	}
	.left {
		position: relative;
	}
	.right {
		h3 {
			font-size: 20px;
			font-weight: bold;
			font-family: $text-font-alegreya;
			color: $darkGreen;
			margin-top: 18px;

			a {
				font-size: 18px;
				float: right;
				font-weight: normal;
				text-decoration: underline;
			}
		}
	}
	h4 {
		font-size: 18px;
		font-weight: bold;
		font-family: $text-font-alegreya;
		color: $darkGreen;
	}

	p.NoColourSelected,
	p.NoSizeSelected {
		line-height: 19px;
		padding: 0 0 10px;
	}

	p.NoSizeSelected {
		padding: 0px;
		margin-bottom: 15px;
	}

	&-gallery {
		ul {
			position: relative;
		}

		li {
			position: relative;
			display: none;

			&._show {
				display: block;
			}
		}

		a {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			max-width: 400px;
			max-height: 500px;
			margin: 0 auto;
			overflow: hidden;
			background-size: cover;
			background-repeat: no-repeat;
			text-decoration: none;
		}

		img {
			display: block; /* fixing 4px inline margin */
			width: 100%;
			opacity: 1;
			transition: opacity 0.3s;
			filter: blur(4px);

			&._loaded {
				opacity: 0;
			}
		}
	}

	&-props {

	}

	&-despcription {

	}

	._select-quantity {
		margin-top: 18px;
	}

	&-counter {
		margin-top: 16px;
		text-align: center;
		overflow: hidden;

		li {
			display: inline-block;
			width: 12px;
			height: 12px;
			margin: 2px;
			border-radius: 50%;
			border: 2px solid $inputBG;

			&.active {
				width: 14px;
				height: 14px;
				border-color: $action;
				background: $action;
			}
		}
	}


	@include susy-breakpoint($desktop) {
		&-gallery {
			width: 100px;

			ul {
				max-height: 470px;
				overflow-y: scroll;
			}

			li {
				display: block;
				width: 52px;
				height: 52px;
				margin-bottom: 4px;
				border: 2px solid $inputBG;

				&._current {
					border-color: $action;
				}
			}

			img {
				filter: none;
			}

			li img._loaded {
				opacity: 1;
			}
		}

		// removing by JS
		&-counter {
			display: none;
		}

		&-screen {
			position: relative;
			width: 364px;
			/*height: 458px;*/
			/*min-height: 470px;*/
			float: right;
			overflow: hidden;

			&._magnifier {
				cursor: zoom-in;
			}

			img {
				width: 100%;
				vertical-align: middle;
			}
			// zoom image
			img._zoom {
				position: absolute;
				left:0;
				top: 0;
				opacity: 0;
				max-width: none;
				max-height: none;
				width: auto;
				height: auto;
				transition: opacity 0.3s;
			}
		}
	}
}