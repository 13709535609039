.breadcrumb {

	border-bottom: 1px solid $whiteGreen2;
	width: 100%;
    text-align: center;
    padding: 0;
	
	ol {
		margin: 4px auto 0px;
		padding: 0 15px;
		width: auto;
		overflow: hidden;
        display: inline-block;
        /* For IE, the outcast */
        zoom:1;
        *display: inline;
		
		li {
			margin: 0;
			padding: 0;
			display: inline-block;
			float: left;
			line-height: 40px;
			
			a {
				position: relative;
				display: block;
				padding-right: 16px;
				padding-left: 12px;
				line-height: 40px;
				font-size: 18px;
				color: $darkGreen;
				text-decoration: none;


				&:after {
					content: "/";
					position: absolute;
					top: 0;
					right: 0;
				}

				&:hover {
					text-decoration: underline;
				}
			}

			&:first-child a {
				padding-left: 0;
			}

			&:nth-last-child(1) a {
				padding-right: 0;
				&:after {
					content: "";
				}
			}

		}
	}
}