.product-item-props {
	._price {
		display: inline-block;
		padding: 4px 10px;
		color: $action;
		border: 1px solid $inputBG;
		text-align: center;
		font-weight: bold;
		font-size: 22px;
	}
	
	._rating {
		margin-top: -40px;
		float: right;
	}

	._percentage {
		font-size: 22px;
		color: $darkGreen;
		padding-top: 10px;
		padding-left: 5px;
		font-weight: bold;
		display: block;
	}

	._select-color {
		margin-top: 24px;

		ul {
			margin-top: 16px;
			overflow: hidden;
		}

		li {
			position: relative;
			float: left;
			margin-right: 10px;
			border: 2px solid transparent;
			margin-bottom: 8px;
			width: 42px;
			height: 42px;
			overflow: hidden;

			&._active {
				border-color: $action;
			}

			span {
				display: block;
				background: rgba(0,0,0, 0.7);
				color: #FFF;
				text-align: center;
				font-size: 13px;
				-ms-transform: rotate(45deg); /* IE 9 */
			    -webkit-transform: rotate(45deg); /* Safari */
			    transform: rotate(45deg);
			    position: absolute;
			    left: 3px;
			    top: 2px;
			    z-index: 99;
			    width: 50px;
			}

			a {
				position: relative;
				display: block;
				width: 40px;
				height: 40px;
				text-indent: -999em;
				border: 1px solid transparent;
				transition: border 0.3s;
			}
		}

		span._selected-color {
			margin-left: 12px;
			font-weight: normal;
			color: $attention;
		}
	}

	._select-sizes {
		ul {
			display: none;
			margin-top: 16px;
			overflow: hidden;
			border-left: 1px solid $inputBG;

			&._active {
				display: block;
			}
		}

		li {
			min-width: 40px;
			margin-top: 0;
			border: 1px solid $inputBG;
			border-left: none;

			float: left;

			@include susy-media($smallTablet) {
				min-width: 50px;
			}
			a {
				width: 100%;
				padding: 6px 4px;
				text-align: center;
				font-size: 15px;

				&._no-stock {
					background: #EEE;
					color: #333;
				}
				
			}

			@include susy-media($smallTablet) {
				a {
				fontont-size: 18px;
				}
			}

			&._active {
				background: $inputBG;

				a {
					font-weight: bold;

					&._no-stock { 
						background-image: -webkit-linear-gradient(108deg, $inputBG 50%, #EEE 50%);
					}
				}
			}

			&._disabled a {
				cursor: default;
				color: $grey;
			}
		}

		a {
			position: relative;
			display: block;
			text-decoration: none;
			color: $darkGreen;
		}
	}

	._select-sizes {
		h3 {
			em {
				padding-left: 12px;
				font-style: normal;
				font-weight: normal;
				color: $attention;
			}
		}
	}

	._select-quantity {

		button {
			height: 50px;
			line-height: 46px;
			font-size: 20px;
			padding-left: 15px;
			padding-right: 15px;
		}

		h3 {
			font-size: 16px;
			float: right;
			display: inline-block;
			margin: 0 0 0 20px;
			padding-top: 6px;


			@include susy-media($smallTablet) {
				float: none;
				font-size: 20px;
			}
			label {
				margin: 0 0 0 12px;
				display: inline-block;
			}

			select {
				padding-top: 6px;
				padding-bottom: 6px;
				padding-left: 15px;
				background-position: 88% center;
				height: 35px;
				width: 60px;
				font-size: 18px;
			}

			@include susy-media($smallTablet) {
				select {
					width: 100px;
					font-size:20px;
				}
			}
		}

	}
}


// RATING
._rating {

	a {
		display: inline-block;
		text-decoration: none;
	}

	.fa {
		&-star {
			position: relative;
			display: inline-block;
			width: 20px;
			height: 20px;

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
			}

			&-full {
				&:after {
					background: url('/img/assets/icn-star-full.png') no-repeat 50%;
					background-size: cover;
				}
			}

			&-half {
				&:after {
					background: url('/img/assets/icn-star-half.png') no-repeat 50%;
					background-size: cover;
				}
			}

			&-empty {
				&:after {
					background: url('/img/assets/icn-star-empty.png') no-repeat 50%;
					background-size: cover;
				}
			}

		}
	}

	.product-item-rating {
		position: relative;
		display: inline-block;
		top: -5px;
		margin-left: 10px;
		font-size: 12px;
		font-family: $btn-font-roboto;
		color: $grey;
	}
}