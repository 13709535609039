.play-me {
  position: relative;
  padding: 10px 15px;
  display: block;
  background: $orange;
  border-radius: 5px;
  font-size: 24px;
  color: $whiteOnDarkGreen;
  margin: 5px 10px 10px -10px;
  cursor: pointer;
  width: 150px;
  text-align: center;

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid $orange;
    position: absolute;
    top: -15px;
    left: 10px;
    z-index: 999;
  }
}

