ul.side-menu {

    margin-top: 10px;

    a {
        display: block;
        padding: 3px 0; 
        text-decoration: none;
        color: $darkGreen;
        font-size: 18px;

        &:hover {
            color: $action;
        }
    }

    li.active {
        a {
            font-weight: bold;
        }
    }
}