.related-products,
.frequently-bough-with,
.similar-items {
	.notice-sale,
	.notice-specialOffer,
	.notice-comingSoon,
	.notice-newIn {
		display:none;
	}
}
.search-result {

	._product {
		width: 100%;
		display: inline-block;
		margin-bottom: 24px;

		._wrap {
			max-width: 291px;
			display: block;
			position: relative;
			overflow: hidden;
			margin-left: auto;
			margin-right: auto;

			&:hover {
				text-decoration: underline;
				._description, ._colors {
					max-height: 15rem;
					opacity: 1;
				}
			}

			.img-area {
				position: relative;

				img {
					position: relative;
					z-index: 3;
				}

				.notice-sale,
				.notice-specialOffer,
				.notice-comingSoon,
				.notice-newIn {
					position: absolute;
					z-index: 5;
					width: 100%;
					height: 26px;
					line-height: 26px;
					font-size: 20px;
					background: rgba(242,102,74,0.7);
					color: #FFF;
					text-align: center;
					text-transform: uppercase;
					top: 0;
				}

				.notice-specialOffer {
					background: rgba(242,153,74,0.7);
				}
				.notice-comingSoon {
					background: rgba(74,137,242,0.7);
				}
				.notice-newIn {
					background: rgba(74,242,232,0.8);
					color: #000;
				}
			}
		}

		h3 {
			margin: 4px 4px 0 4px;
			color: #000;
			font-size: 16px;
			font-weight: normal;
		}

		._details {
			max-width: 291px;
			margin-left: auto;
			margin-right: auto;
			color: #000;
			padding: 3px;

			span.salenew{
				color: #000;
				font-size: 22px;
				margin-bottom: 3px;
				font-weight: bold;
				display: block;
				text-align: center;
			}

			._brand {
				display: block;
				line-height: 20px;
				font-size: 18px;
				&:hover {
					text-decoration: none;
				}
			}

			._price {
				display: block;
				line-height: 22px;
				font-size: 18px;
				._rrp {
					font-size: 16px;
					color: $subtleOnDarkGreen;
					s {
						text-decoration: line-through;
					}
				}
				._percentage {
					display: block;
					font-size: 22px;
					color: $darkGreen;
					text-align: center;
					font-weight: bold;
					padding: 2px;
					border: 1px solid $whiteGreen1;
				}
			}
		}

		._description, ._colors {
			position: relative;

			a {
				text-decoration: none;
			}
		}

		._colors {
			margin-top: 3px;
			i {
				position: relative;
				display: inline-block;
				width: 25px;
				height: 25px;
				margin: 6px 4px 2px 0;
				&.red {background: red;}
				&.green {background: green;}
				&.blue {background: blue;}
				&.yellow {background: yellow;}
			}
		}
		
		p {
			margin: 0;
			color: #000;
		}
	}

	@include susy-breakpoint($smallTablet $tabletLandscape) {
		._product-3,
		._product-4,
		._product-5  {
			float: left;
			width: calc(50% - 4px);

			margin-right: 4px;
		}
	}

	@include susy-breakpoint($tabletLandscape) {

		._product {
			height: 430px;

			img {
				max-height: 376px;
			}
		}

		._product-3 {
			float: left;
			width: calc(100%/3 - 6px);

			/* First box */
			&:nth-child(3n + 1) {
				margin-left: 0;
				margin-right: 4px;
			}

			/* Second box */
			margin-left: 4px;
			margin-right: 4px;

			/* Forth box */
			&:nth-child(3n + 0) {
				margin-left: 4px;
				margin-right: 0;
			}
		}
		
		._product-4{
			float: left;
			width: calc(100%/4 - 6px);
			height: 420px;
			
			/* First box */
			&:nth-child(4n + 1) {
				margin-left: 0;
				margin-right: 4px;
			}

			/* Second box & Third Box */
			margin-left: 4px;
			margin-right: 4px;

			/* Forth box */
			&:nth-child(4n + 0) {
				margin-left: 4px;
				margin-right: 0;
			}
		}
		
		._product-5{
			float: left;
			width: calc(100%/5 - 7px);
			height: 415px;
			
			/* First box */
			&:nth-child(5n + 1) {
				margin-left: 0;
				margin-right: 4px;
			}

			/* Second box & Third Box */
			margin-left: 4px;
			margin-right: 4px;

			/* Forth box */
			&:nth-child(5n + 0) {
				margin-left: 4px;
				margin-right: 0;
			}
		}
	}	
}