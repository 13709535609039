.special-offers {

	.special-offer {
		margin-top: 20px;
		border: 1px solid $inputBG;
		padding: 20px;

		img {
			margin-top: 10px;
			max-width: 100%;
		}

		h3 {
			margin-top: 10px;
			font-size: 30px;
		}

		h4 {
			margin-top: 0;
			color: $subtleOnDarkGreen;
			font-size: 30px;
			border: 2px dashed $green;
			background: $whiteGreen1;
			text-align: center; 
			padding: 5px 0;
			&.margin-top {
				margin-top: 30px;
			}
		}

		.type{
			display:block;
			background: $darkGrey;
			color: $whiteOnDarkGreen;
			text-align: center;
			padding: 5px 0;
			font-size: 20px;
			margin: 10px 0;

			&.offer {
				background: $titleBg;
			}
		}
	}

}

.special_offers {

	.special_offer {
		margin: 10px;
		padding: 10px;
		border: 1px solid #000;
		display: block;


		&:hover,
		&:focus {
			background-color: $whiteGreen1;

			.special_offer-content {
				span.button {
					background-color: $action;
				}
			}
		}

		.left {
			width: 99%;

			@include susy-breakpoint($laptop) {
				width: 65.35514%;
			}
		}

		.right {
			width: 99%;

			@include susy-breakpoint($laptop) {
				width: 32.33%;
			}
		}

		&-image {
			margin-top: 10px;
			display: block;
			text-align: center;
			@include susy-breakpoint($laptop) {
				margin-top: 0px;
			}
		}

		&-content {
			text-align: center;
			h4 {
				text-transform: uppercase;
				color: #64574f;
				font-weight: normal;
				font-size: 18px;
				margin: 5px 0 0;
				line-height: 20px;
			}

			h3 {
				font-weight: normal;
				font-size: 26px;
				color: #000;
				margin: 5px 0px;
				padding: 0 15px;
			}

			p {
				text-align: center;
				color: #000;
				font-size: 14px;
				margin: 5px 0;

				&.offer {
					font-size: 24px;
					color: #64574f;
					margin: 0;
				}
			}

			span.button {
				color: #FFF;
				font-weight: bold;
				text-transform: uppercase;
				background: #ac9a84;
				padding: 10px 0;
				display: block;
				width: 120px;
				margin: 10px auto 5px;
			}

			span.note {
				color: #64574f;
				font-size: 14px;
				line-height: 16px;
				margin: 0;
			}
		}

	}

	&.full-width {
		overflow: hidden;
		.special_offer {
			&:first-of-type {
				margin: 0 0 10px;
			}
		}
	}
	
}