.product-search ._panel {
	top: 40px;
	position: absolute;
	height: 0;
	background: #fff;
	left: 0;
	right: 80px;
	z-index: 1;
	visibility: hidden;
	transition: opacity 0.3s;
	/*overflow: hidden;*/

	&._show {
		visibility: visible;
		height: 300px;
	}

	ul {
		overflow-y: scroll;
		height: 100%;
	}

	a {
		padding: 12px 8px;
		display: block;
		text-decoration: none;
		border-bottom: 1px dotted $lightGreen;

		&:hover, &:focus {
			text-decoration: underline;
		}
	}

	@include susy-media($smallTablet) {
		left: 132px;
	}
}