._mobile {
  overflow-y: hidden;
}

.menu {
  &-basket, &-hamburger, &-sign-in, &-search a, &-currency a {
    display: none;
  }

  ._drop-down {
    top: 8px;
  }
}

@mixin props {
  width: 26px;
  text-indent: -100em;
  border: none;
  background-size: contain;
  display: block;
}

/* cloning icns on Close panel */
._mobileIcons {
  ._close {
    ._item {
      position: relative;
      display: inline-block;
      width: 38px;
      height: 32px;
      top: 15px;
      margin-left: 16px;
      text-align: center;
      overflow: hidden;

      a {
        @include props;
        width: 30px;
        height: 26px;
        padding: 0;
      }
    }

    .menu-sign-in a {
      background: url('/img/assets/icn-lock.svg') no-repeat 50%;
      background-size: contain;
    }

    .menu-basket {
      a {
        background: url('/img/assets/icn-cart.svg') no-repeat 50%;
        background-size: contain;
      }
      &-amount {
        position: absolute;
        border-radius: 50%;
        background: $action;
        color: #fff;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 20px;
        padding-top: 3px;
        text-align: center;
        font-size: 12px;
        text-indent: initial;
      }
    }
  }
}

._mobileIcons .menu {
  ._item {
    position: relative;
    display: none;
    width: 30px;
    height: 32px;
    top: 15px;
    margin-left: 20px;
    text-align: center;
    overflow: hidden;

    &:last-child {
      display: inline-block;
    }
  }

  .menu-currency {
    display: inline-block;

    a {
      background: url('/img/assets/icn-currency.svg') no-repeat 50%;
      height: 24px;
      @include props;

      &.pound {
        background: url('/img/assets/icn-pound-white.svg') no-repeat 50%;
      }
      &.dollar {
        background: url('/img/assets/icn-dollar-white.svg') no-repeat 50%;
      }
      &.euro {
        background: url('/img/assets/icn-euro-white.svg') no-repeat 50%;
      }
    }
  }

  .menu-sign-in {
      display: inline-block;
      a {
          background: url('/img/assets/icn-lock-white.svg') no-repeat 50%;
          height: 24px;
          @include props;
      }
  }

  .menu-basket {
    display: inline-block;

    a {
      background: url('/img/assets/icn-cart-white.svg') no-repeat 50%;
      height: 24px;
      @include props;
    }
    &-amount {
      position: absolute;
      width: 20px;
      height: 20px;
      padding-top: 3px;
      right: 0;
      bottom: 0;
      color: #fff;
      text-align: center;
      font-size: 12px;
      border-radius: 50%;
      background: $action;
      text-indent: initial;
    }
  }

  /*._hide .menu-basket-amount {display: none;}*/

  .menu-hamburger {
    a {
      background: url('/img/assets/icn-hamburger.svg') no-repeat 50%;
      @include props;
      height: 20px;
      margin-top: 3px;
    }
  }

  ._drop-down {
    display: none;
    position: absolute;
    width: auto;
    top: 8px;
    left: -11px;
    right: -11px;
    padding: 10px;
    z-index: 4;
    background: $inputBG;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);

    &._show {
      display: block;
      top: 56px;
    }

    button {
      height: 30px;
      line-height: 26px;
    }
  }

  ._input-send-btn input {
    width: calc(100% - 80px);
    height: 30px;
    line-height: 30px;
  }

  a {
    background: url('/img/assets/icn-search.svg') transparent no-repeat 50%;
    height: 24px;
    margin-top: 1px;
    @include props;
  }

  @include susy-breakpoint($smallTablet) {

    &-right {
      ._drop-down {
        width: 340px;
        right: 0;
        left: inherit;
      }
    }

    &-search a {
      display: block;
    }

    ._item {
      display: inline-block;
    }

  }
}

.menu-mobile {
  position: fixed;
  width: 300px;
  top: 0;
  right: -300px;
  bottom: 0;
  background: $inputBG;
  z-index: 15;
  color: $darkGreen;
  transition: right 0.3s;

  > ul > li {
    border-bottom: 1px solid $whiteGreen3;
  }

  &._hide {
    visibility: hidden;
    right: -300px;
  }

  ._close {
    position: relative;
    height: 56px;
    text-align: right;

    div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 166px;
    }
  }

  button {
    padding: 18px 40px 17px 0;
    border: none;
    background: url('/img/assets/icn-cross.svg') no-repeat 72% 50%;
    background-size: 16px;
    font-family: $text-font-alegreya;
    font-size: 18px;
  }

  a {
    display: block;
    color: $darkGreen;
    background: $whiteGreen1;
    font-size: 18px;
    text-decoration: none;
    padding: 22px 0 23px;

    &:hover {
      text-decoration: underline;
    }
  }

  > ul {
    position: absolute;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    top: 56px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  // Category
  > ul > li > a {
    position: relative;
    padding-left: 32px;
    font-weight: bold;
    background: $whiteGreen1;
  }

  // Sub-Category
  &-sub {
    ul {
      border-bottom: 1px solid $whiteGreen3;
    }

    li:first-child a {
      background: $whiteGreen2;
      padding-left: 48px;
    }
  }

  // Sub-Category-items
  &-sub {
    li:not(:first-of-type) a {
      background: $whiteGreen3;
      padding-left: 64px;
      border-top: 1px solid $pageBgGreen1;
    }

    li:first-of-type a {
      margin-right: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .has-sub {
    position: relative;

    //button for submenu
    > ._btn-cat {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 66px;
      cursor: pointer;
      background: rgba(220, 220, 189, 0.5);
      z-index: 1;
      &:after {
        content: "-";
        position: absolute;
        top: 34%;
        right: 37%;
        font-size: 22px;
        font-family: $helvetica-font;
      }
    }

    &._hide {
      border-bottom: 1px solid $pageBgGreen1;
      .menu-mobile-sub {
        display: none;
      }

      // closed
      ._btn-cat {
        &:after {
          content: "+";
        }
      }
    }

    // sub-cats
    ul {
      li {
        position: relative;

        div._btn-cat {
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 66px;
          cursor: pointer;
          background: rgba(202, 202, 176, 0.5);
          z-index: 1;
          &:after {
            content: "-";
            position: absolute;
            top: 34%;
            right: 37%;
            font-size: 22px;
            font-family: $helvetica-font;
          }
        }
      }

      &._hide {
        div._btn-cat {
          &:after {
            content: "+";
          }
        }

        li:not(:first-child) {
          display: none;
        }
      }
    }
  }

}

._overlay {
  visibility: hidden;
  opacity: 0;
}

._mobile {
  .menu-mobile {
    visibility: visible;
    right: 0;
    &._hide {
      right: -300px;
    }
  }

  ._overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: black;
    z-index: 4;
    transition: opacity 300ms;
    visibility: visible;

    &._show {
      opacity: 0.5;
    }
  }
}
