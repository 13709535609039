.binocular-rental {
    .intro {
        text-align: center;
        max-width: 900px;
        margin: 0 auto;
    }

}

.binocular-hero {
    position: relative;
    min-height: 380px;
    background: url('/img/assets/binoculars-bg.jpg') no-repeat;
    background-position: right top 16%;
    background-size: cover;


    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(242,240,227,1);


        &:before, &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dcd7b4+0,dcd7b4+50,ffffff+51,ffffff+100&1+0,1+50,0+51,0+100 */
            background: -moz-linear-gradient(left, rgba(220, 215, 180, 1) 0%, rgba(220, 215, 180, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(220, 215, 180, 1) 0%, rgba(220, 215, 180, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(220, 215, 180, 1) 0%, rgba(220, 215, 180, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            background-size: 6px;
        }

        &:before {
            top: 3px;
        }

        &:after {
            bottom: 4px;
        }
    }

    .container {
        @include clearfix;
    }

    h3 {
        color: $darkGreen;
    }

    .text {
        padding: 24px 0;
    }

    ol {
        color: $darkGreen;
        padding: 0 14px;
    }

    li {
        margin-top: 8px;
        list-style: decimal;
    }

    @include susy-breakpoint($smallTablet) {
        &-bg {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f2f0e3+0,f2f0e3+40,f2f0e3+100&1+0,1+44,0.85+58,0.25+68,0.25+100 */
            background: -moz-linear-gradient(left, rgba(242,240,227,1) 0%, rgba(242,240,227,1) 40%, rgba(242,240,227,1) 44%, rgba(242,240,227,0.85) 58%, rgba(242,240,227,0.25) 68%, rgba(242,240,227,0.25) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(242,240,227,1) 0%,rgba(242,240,227,1) 40%,rgba(242,240,227,1) 44%,rgba(242,240,227,0.85) 58%,rgba(242,240,227,0.25) 68%,rgba(242,240,227,0.25) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(242,240,227,1) 0%,rgba(242,240,227,1) 40%,rgba(242,240,227,1) 44%,rgba(242,240,227,0.85) 58%,rgba(242,240,227,0.25) 68%,rgba(242,240,227,0.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }

        .text {
            max-width: 300px;
        }
    }

    @include susy-breakpoint($smallTablet) {
        .text {
            max-width: 400px;
        }
    }

    @include susy-breakpoint($laptop) {
        .text {
            max-width: 600px;
        }
    }
}

.binocular-description {
    h3, h4 {
        color: $darkGreen;
    }
    ol {
        padding: 0;
    }
}