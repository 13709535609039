.no-js {
    .slowLoad,
    .no-js-hide {
        display: none;
    }
}

.hide {
  display: none !important;
}

.wrap {
    
    // example of strict container, fixed max-width and margin auto
    // @include container(40em);
    overflow: hidden;

    &._bg-dark {
        background: #DCD7B4;
    }

    > section,
    > form > section {
        margin: 20px 0 0;
    }

    // first container element distance from parent sides
    > section,
    > form > section,
    .footer {
        padding: 0 10px;
    }

    > section.full-width {
        padding: 0;
        margin: 0;
    }
}

.body-min-height {
    min-height:300px;
}

.container {
    position: relative;
    margin: 0 auto;
    max-width: 1200px;
    @include clearfix;

    &-narrow {
        max-width: 800px;

        p {
            font-size: 18px;
        }
    }
}



.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

div._disabled {
    position: relative;

    &:before {
        content:"";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
    };
}

.video-wrapper iframe {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

section {
    &.alt {
        background: $whiteGreen;
        padding: 50px 20px;
        margin: 20px 0;

        @include susy-media($tabletLandscape) {
            margin: 50px 0;
        }
    }
}

/*
This is sad.. I mean, all those important(S). oh well..
*/
.margin-top {
  margin-top: 25px !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.margin-bottom {
  margin-bottom: 25px !important;
}

.margin-bottom-large {
  margin-bottom: 40px !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

%row {
  width: 100%;
  display: inline-block;
}

.right {
  float: right;
}

audio {
  width: 100%;
}

// just extend %row and no needs to use this class
.row {
  @extend %row;
}

button, a.submit {
  font-family: $btn-font-roboto;
}

section._hide,
button._hide,
label._hide,
div._hide,
.form label._hide,
p._hide,
a._hide,
span._hide {
  display: none !important;
}

.clear {
  @include clearfix;
}

.prices {
  margin: 24px 0 0;
  font-size: 1.2rem;

  dt {
    min-width: 80px;
    float: left;
    clear: left;
    text-align: left;
    font-weight: bold;
  }
  dt::after {
    content: ":";
  }
  dd {
    margin: 0 0 0 88px;
    padding: 0 0 0.5em 0;

    &.action {
      color: $action;
    }
  }
}

body.scrollDisabled {
  position: fixed;
  width: 100%;
}

@media (min-width: $laptop) {
  .min-height-400 {
    min-height: 400px;
  }
}
