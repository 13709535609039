.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 8px;
  bottom: 0;
  width: 100%;
  text-align: center;
  pointer-events: none;

  @include susy-media($smallTablet) {
    bottom: -100%;
    transition: bottom 0.3s;

    :hover {
      bottom: 0;
    }

    &-hover .tooltip {
      bottom: 0;
    }
  }

  &-wrapper {
    display: none;
    align-items: center;
    position: absolute;
    width: 300px;
    height: 100%;
    top: 0;
    right: -316px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &-modal {
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 1px 1px 3px 0;
    background: $inputBG;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 10px 8px 0;
      border-color: transparent $inputBG transparent transparent;
      left: -10px;
      top: calc(50% - 8px);
    }
  }
}

@media (max-width: $desktop) {
  .tooltip-wrapper {
    display: none;
  }
}

@media (min-width: $desktop) {
  .tooltip {
    display: none;
  }
}
