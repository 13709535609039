
@mixin column($width) {
  -webkit-columns: $width;
  -moz-columns: $width;
  columns: $width;
}

.story-page {

    padding: 0 20px;
    
    @include susy-breakpoint($desktop) {
        padding: 0;
    }

  	section {
    		margin-bottom: 10px;

    		&.full-width{
    			  width: 100%;
    		}
  	}

  	p {		
        font-size: 20px;
        line-height: 24px;
        color: #333;
    		text-align: justify;
    		
    		&.right {
    			  text-align: right;
    		}
        &.center {
            text-align: center;
        }
  	}

    h2, h3 {
        color: $darkGreen;
    }

    @include susy-breakpoint($smallTablet) {
        blockquote {
            font-size: 2.5em;
        }
    }

  	.left .right {
  		p {
  			text-align: justify;
  			text-indent: 20px;
  			font-size: 1.1em;
  		}
  	}

  	.transcript {
  		p {
  			font-size: 0.9em;
  		}
  	}

  	.full-width {
      img {
        width: 100%;
      }
    }

    article {
      &.narrow {
        max-width: 800px;
        margin: 0 auto;
      }
      /* READ MORE*/
      button[data-js="read-more-button"] {
        margin-top: -5px;
        .no-js & {
          display: none;
        }

        i {
          font-style: normal;
        }
      }

      div[data-js="read-more-content"] {
        display: none;

        .no-js & {
          display: block;
        }

        &.show {
          position: relative;
          display: block;
          opacity: 0;
          transition: opacity 0.5s, transform 0.5s;

          &.slide-in {
            opacity: 1;
          }
        }
      }
    }
    
    /* SLIDE */
    .slide {
      position: relative;
      opacity: 0;
      transition: opacity 0.5s, transform 0.5s;

      &-in {
        opacity: 1;
      }
    }

    .left .slide {
      transform: translate3d(-24px, 0, 0);

      &-in {
        transform: translate3d(0, 0, 0);
      }
    }

    .right .slide {
      transform: translate3d(24px, 0, 0);

      &-in {
        transform: translate3d(0, 0, 0);
      }
    }

    /* FADE */
    .fade {
      .no-js & {
        display: block;
        opacity: 1;
      }

      display: block;
      opacity: 0;
      transition: opacity 0.5s;

      &-in {
        opacity: 1;
      }
    }

    video {
      width: 100%;
    }

    div.narrow{
        width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    .panel {

        margin-bottom: 10px;

        a {
            display: block;
            position: relative;

            p {
                display: table;
                position: absolute;
                margin: 0;
                bottom: 0;
                width: 100%;
                text-align: center;
                font-size: 1.3em;
                line-height: 1.4em;
                color: $white;

                min-height: 40px;
                background: rgba(0,0,0,0.4);

                span {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 16px 8px;
                }
            }

            img {

                display: block;
                width: 100%;

                + p {
                    visibility: hidden;
                    opacity: 0;
                    transition: font-size 0.5s, line-height 0.5s,  opacity 0.3s, visibility 0.3s;
                }


                &:hover, &:focus {

                    + p {
                        font-size: 1.6em;
                        line-height: 1.7em;
                    }
                }
            }

            img._loaded + p {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
