.wrap > .prompt-delivery {
	margin-top: 2px;
	padding-top: 0;
	border-top: 2px solid $lightGreen;
}
.prompt-delivery {
	a._map {
		display: block;
		margin: 48px 0;
		text-align: center;
		text-decoration: none;
	}

	img {
		@include clearfix;
	}

	span {
		display: inline-block;
		margin-top: 24px;
		color: $darkGreen;
		border-bottom: 1px dotted $darkGreen;
	}

	a:hover span {
		border-bottom-style: solid;
	}

	@include susy-breakpoint($tabletLandscape) {

		a._map {
			min-height: 350px;
			position: relative;

			p {
				position: absolute;
				width: 100%;
				bottom: 0;
			}

			span {

			}
		}
	}
}