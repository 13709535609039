section.adverts {
	
	margin: 30px 20px;
	
	@include susy-breakpoint($desktop) {
		margin: 50px 0;
	}

	.advert {
		
		position: relative;
		margin-bottom: 20px;
		
		a {

			display: block;
			text-align: center;
			width: 100%;
			height: 100%;
			overflow: hidden;

			img {
				z-index: 1;
				width: 100%;
	  		}
		
			&:hover {
				em {
					text-decoration: underline;
				}
	  		}


			em {
				display: block;
				width: 100%;
				height: 40px;
				color: $darkGreen;
				font-style: normal;
				font-size: 20px;
				line-height: 40px;
				text-align: center;
			}	
		}
	}
}