table {

    &.basic {
        tr {
            &:nth-child(odd) {
                background-color: $white;
            }

            &:nth-child(even) {
                background-color: $whiteGreen;
            }

            th {
                padding: 13px;
                background: $whiteGreen1;
                font-size: 15px;
                text-align: left;
            }

            td {
                padding: 10px;
                text-align: left;
            }
        }
    }

    &.full-width {
        min-width: 100%;
    }

}