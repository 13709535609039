.product-description {

	.container {
		border-top: 1px solid $inputBG;
		margin-top: 20px;
	}

	.left {
		position: relative;

		&:after {
			content: "";
			position: absolute;
			display: none;
			width: 1px;
			top: 24px;
			right: 0;
			bottom: 0;
			background: $inputBG;
		}
	}

	dl {
		position: relative;
		min-height: 236px;
	}

	dt, dd {
		margin: 0;
		padding: 14px 20px;
	}

	dt {
		position: relative;
		margin-top: 4px;
		font-size: 18px;
		color: $darkGreen;
		font-weight: bold;
		background: $inputBG;
		cursor: pointer;

		&:after {
			content: "";
			position: absolute;
			width: 16px;
			height: 10px;
			top: 20px;
			right: 10px;
			background: url('/img/assets/icn-triangle.svg') no-repeat;
			transform: rotate(-90deg);
			transition: transform 0.3s;
		}

		&._current {
			&:after {
				transform: rotate(0);
			}
		}
	}

	dt + dd {
		display: none;
	}

	dt._current + dd {
		display: block;
	}

	dd {
		border: 1px solid $inputBG;
		> *:first-child {
			margin-top: 0;
		}
	}

	/* RELATED PRODUCTS */

	._wrap {
		margin-top: 24px;
	}

	/* PRODUCT REVIEW */

	&-form {

		#reviewName {
			border: 1px solid $inputBG;
			background: none;
		}

		#reviewEmail {
			border: 1px solid $inputBG;
			background: none;
		}

		textarea {
			width: 100%;
			height: 80px;
			padding: 8px;
			border: 1px solid $inputBG;
		}

		._hide {
			display: none;
		}

		._submit {
			width: 100%;
			display: inline-block;

			button._disabled {
				opacity: 0.5;
				outline: none;
				cursor: default;
			}
		}

		&._hide {
			display: none;
		}
	}

	/* PRODUCT REVIEW RESPONSE MESSAGE */

	&-responseMsg {
		padding: 10px;
		max-width: 500px;
		margin: 24px auto 0;
		text-align: center;
		background: $failed;
		border: 1px solid $failedBG;

		&._hide {
			display: none;
		}

		&._failed {
			background: $failedBG;
			border: 1px solid $failed;
		}

		&._success {
			background: $successBG;
			border: 1px solid $success;
		}
	}

	/* SIZE CHART */

	&-sizeChart {
		width: 100%;
		text-align: center;

		tr {
			&:nth-child(odd) {
				background: $whiteOnDarkGreen;
			}

			&:nth-child(even) {
				background: $whiteWithGreenTone;
			}
		}

		th {
			border-bottom: 1px dotted $inputBG;
			padding: 16px 8px;
		}

		thead th {
			background: $titleBg;
			color: $white;
		}

		th a {
			padding: 4px;
			font-size: 18px;
			text-decoration: none;
			color: rgba(255,255,255, 0.5);
			font-weight: bold;

			&:hover {
				text-decoration: underline;
				color: rgba(255,255,255, 0.8);
			}

			&._active {
				padding: 4px;
				color: rgba(255,255,255, 1);
			}
		}

		td {
			border-bottom: 1px dotted $inputBG;
			padding: 8px 2px;
		}

		tbody tr th {
			width: 118px;
		}
	}

	@include susy-breakpoint($laptop) {
		.left {
			&:after {
				display: block;
			}
		}

		dt {
			position: absolute;
			min-width: 200px;
			height: 50px;
			left: 0;
			background: none;
			font-weight: normal;

			&:after {
				display: none;
			}

			&._current {
				font-weight: bold;
				background: $inputBG;
				cursor: default;

				&:before {
					content: "";
					position: absolute;
					width: 0;
					height: 0;
					top: 0;
					right: -15px;
					border-top: 25px solid transparent;
					border-bottom: 25px solid transparent;
					border-left: 15px solid $inputBG;
				}
			}

			&:nth-of-type(8n+2) {
				top: 60px;
			}
			&:nth-of-type(8n+3) {
				top: 120px;
			}
			&:nth-of-type(8n+4) {
				top: 180px;
			}
			&:nth-of-type(8n+5) {
				top: 240px;
			}
			&:nth-of-type(8n+6) {
				top: 300px;
			}
			&:nth-of-type(8n+7) {
				top: 360px;
			}
			&:nth-of-type(8n+8) {
				top: 420px;
			}
			&:nth-of-type(8n+9) {
				top: 480px;
			}
		}

		dd {
			width: 100%;
			padding-left: 232px;
			float: right;
			border: none;
		}
// TABLE LAYOUT
		&-sizeChart {
			table-layout: fixed;
		}

	}
}