.global-6-6 {
	.left, .right {
		@include span(12 of 12);
	}

	@include susy-media($smallTablet) {
		.left, .right {
			@include span(6 of 12);
		}

		.right {
			@include last;
		}
	}
}