.pleaseWait {
  position: fixed;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  opacity: 0;
  transition: opacity 0.4s;
}

.pleaseWait:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: $footerGreen transparent $inputBG transparent;
  animation: pleaseWait 1.2s linear infinite;
}

@keyframes pleaseWait {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pleaseWait-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0,0,0);
  opacity: 0;
  transition: opacity 0.4s;
}
