.anchor-links {

	margin: 30px 0;
	border: 1px solid $whiteGreen2;
	background: $whiteGreen;

	ul {
		margin: 20px;

		li {
			list-style: circle;
			padding: 0;
			margin: 0px 0px 8px 20px;

			a {
				font-size: 18px;
				display: block;
				text-decoration: underline;
			}
		}
	}
}

.answers {
	border-bottom: 1px solid $whiteGreen2;
	margin: 10px 0 20px;

	&:last-child {
		border-bottom: none;
	}

	h4 {
		font-size: 22px;
		margin-bottom: 10px;
	}
}