.banner-form {

	margin-top: 50px;

	&-container {
		position: relative;
		max-width: 800px;
		margin: 0 auto;
		padding: 9px 12px 27px;
		background: rgba(0,0,0,0.5);
		margin-bottom: 20px;
	}

	form {
		overflow: hidden;
	}

	.row {
		.left, .right {
			@include span(4 of 8);
		}
		.right {
			@include last;
		}
	}

	span,
	p {
		color: $white;
		text-align: center;
		margin-bottom: 0;
	}

	#end-date{
		text-align: left;
		float:left;
		clear: both;
		margin: 0 0 -15px;
		span{
			color: $action;
		}
	}

	&-price {
		position: absolute;
		padding: 4px 16px;
		background: rgba(255,255,255,0.2);
		bottom: 12px;
		left: 12px;
		margin-top: 4px;

		span {
			display: block;
			text-align: center;

			&:first-child {
				font-size: 24px;
				color: $action;
			}

			&:last-child {
			}
		}
	}

	&-link {
		span {
			color: inherit;
		}
	}
}