.sign-up {
	position: relative;
	min-height: 380px;
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 2px;
		top: -4px;
		left: 0;
	}
	&-bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		/* AS PER RAITIS' DESIGN, UNCOMMENT BELOW AND REPLACE EXISTING HERE AND AFTER MEDIA QUERIES:SMALLTABLETS */

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9f8f1+0,f9f8f1+40,f9f8f1+100&1+0,0.95+40,0.75+100 */
		/*background: -moz-linear-gradient(left,  rgba(249,248,241,1) 0%, rgba(249,248,241,0.95) 40%, rgba(249,248,241,0.75) 100%);
		background: -webkit-linear-gradient(left,  rgba(249,248,241,1) 0%,rgba(249,248,241,0.95) 40%,rgba(249,248,241,0.75) 100%);
		background: linear-gradient(to right,  rgba(249,248,241,1) 0%,rgba(249,248,241,0.95) 40%,rgba(249,248,241,0.75) 100%); */

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dcd7b4+0,dcd7b4+40,dcd7b4+100&1+0,0.75+40,0.25+68,0.25+100 */
		
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dcd7b4+0,dcd7b4+40,dcd7b4+100&1+0,0.75+40,0.25+68,0.25+100 */
		background: -moz-linear-gradient(left, rgba(255,255,255,1) 40%, rgba(255,255,255,0.25) 68%, rgba(255,255,255,0.25) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(255,255,255,1) 40%,rgba(255,255,255,0.25) 68%,rgba(255,255,255,0.25) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(255,255,255,1) 40%,rgba(255,255,255,0.25) 68%,rgba(255,255,255,0.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

		&:before, &:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dcd7b4+0,dcd7b4+50,ffffff+51,ffffff+100&1+0,1+50,0+51,0+100 */
			background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 51%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			background-size: 6px;
		}

		&:before {
			top: 3px;
		}

		&:after {
			bottom: 4px;
		}
	}

	.container {
		@include clearfix;
	}

	h1 {
		/*font-size: 40px;
		em {
			font-style: normal;
			font-weight: bold;
			font-size: 56px;
		}*/
	}

	p {
		color: $darkGreen;
		font-size: 18px;
	}

	@include susy-breakpoint($smallTablet) {
		.container {
			padding: 10px 40px;
		}
	}

	@include susy-breakpoint($tabletLandscape) {
		background: url('/img/assets/sign-up.jpg') no-repeat;
		background-position: right top;
		background-size: auto 100%;

		&-bg {
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dcd7b4+0,dcd7b4+100&1+0,1+30,0.95+50,0.5+90,0.25+100 */
			background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0.95) 50%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0.25) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,0.95) 50%,rgba(255,255,255,0.5) 90%,rgba(255,255,255,0.25) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,0.95) 50%,rgba(255,255,255,0.5) 90%,rgba(255,255,255,0.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		}
	}

	@include susy-breakpoint($laptop) {
	}

	@include susy-breakpoint($desktop) {
		/*background-position: right top 16%;
		background-size: 68%;*/
		background-size: 68%;


		&-bg {
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dcd7b4+0,dcd7b4+100&1+0,1+40,0.95+50,0.5+90,0.25+100 */
			background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,0.95) 50%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0.25) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,0.95) 50%,rgba(255,255,255,0.5) 90%,rgba(255,255,255,0.25) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,0.95) 50%,rgba(255,255,255,0.5) 90%,rgba(255,255,255,0.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		}

	}
}