.product-search {
	form {
		position: relative;
		max-width: 600px;
		margin: 0 auto;
	}

	select {
		width: 132px;
		float: left;
		height: 40px;
		background-color: $subtleOnDarkGreen;
		border-radius: 5px 0 0 5px;
		background-position: 85%;

		display:none;
	}

	input {
		width: calc(100% - 80px);
		display: inline-block;
		float: left;
	}

	button.submit {
		width: 80px;
		margin: 0;
	}

	@include susy-media($smallTablet) {
		select {
			display: block;
		}

		input {
			width: calc(100% - 212px);
		}
	}
}
