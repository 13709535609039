.similar-items {

	.container {
		border-top: none;
		margin-top: 0;
		padding: 0 20px;
	}

	@include susy-breakpoint($smallTablet) {
		.container {
			padding: 0;
		}
	}

	.left{
		border: none;
		
		&:after {
			background: none !important;
		}
		@include span(6 of 12);
	}

	.right {
		@include span(6 of 12);
		@include last;
	}

	h3 {
		font-size: 16px;
		margin-top: 5px;
	}

	h4 {
		margin-bottom: 0px;
	}

	._percentage {
		display:block;
		font-weight: bold;
	}

}