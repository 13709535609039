::-webkit-input-placeholder {
	color: rgba(84, 83, 37, 0.5);
	font-size: 0.8em;
}

:-moz-placeholder { /* Firefox 18- */
	color: rgba(84, 83, 37, 0.5);
	font-size: 0.8em;
}

::-moz-placeholder { /* Firefox 19+ */
	color: rgba(84, 83, 37, 0.5);
	font-size: 0.8em;
}

:-ms-input-placeholder {
	color: rgba(84, 83, 37, 0.5);
	font-size: 0.8em;
}

::placeholder {
	color: rgba(84, 83, 37, 0.5);
	font-size: 0.8em;
}

.error {
	position: relative;

	&:before {
		content: "!";
		position: absolute;
		text-align: center;
		width: 16px;
		height: 16px;
		top: 28px;
		right: 10px;
		padding: 4px;
		color: $white;
		background: $action;
		border-radius: 50%;
		line-height: 20px;
		box-shadow: 1px 1px 2px #333;
		z-index: 1;
	}
	&.no-span {
		&:before {
			top: 7px;
		}
	}
}

.notification-bar {
	cursor: pointer;
	position: fixed;
	max-width: 400px;
	top: -100px;
	left: calc(50% - 200px);
	padding: 8px 10px;
	font-size: 18px;
	color: #fff;
	z-index: 999;
	/*background: #f2994a;*/
	text-align: center;
	background: rgba(242, 153, 72, 0.9);
	box-shadow: 0 0 4px 1px black;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	opacity: 0;
	transition: opacity 700ms;

	span{
		display: block;
	}

	a {
		color: #FFF;
		font-size: 14px;
		text-decoration: underline;
	}
}

.table {
	border: 1px solid $subtleOnDarkGreen;
	width: 100%;

	th {
		border: 1px solid $subtleOnDarkGreen;
		font-size: 14px;
		color: $darkGreen;
		background: $whiteGreen2;
		padding: 5px;
		text-align: left;
	}

	td {
		border: 1px solid $subtleOnDarkGreen;
		padding: 3px 8px;
	}
}
