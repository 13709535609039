.menu .js-search-lookup-panel {
  position: absolute;
  width: 100%;
  top: 100%;
  max-height: 400px;
  overflow-y: scroll;
  text-align: left;
  background: $white;
  box-shadow: 0px 1px 4px black;
  left: 0;
  z-index: 99999999;

  > a {
    display: block;
    padding: 8px;
    color: $footerGreen;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* override from menu CSS */
    background: none;
    height: auto;
    margin-top: 0;
    width: 100%;
    font-size: 12pt;
    text-indent: 0;
    background-size: contain;

    &:hover,
    &:focus {
      background: $whiteGreen1;
    }
  }
}